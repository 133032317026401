import { BlogPostData } from '../../utilities/general/blog-feed'
import Columns from '../ui/columns'
import LinkCard, { LinkCardData } from './link-card'
import React from 'react'
import styled from 'styled-components'

export interface BlogFeedProps {
    blogFeed: BlogPostData[]
}

const BlogFeed = ({ blogFeed }: BlogFeedProps): JSX.Element => {
    return (
        <Container>
            <LinkCard
                data={{ linkCard: [blogFeed[0]] }}
                minimalOnMobile={false}
                componentsInSection={blogFeed.length}
                asFeatured={true}
            />
            <Columns asList={true}>
                {blogFeed.slice(1, 4).map((post: LinkCardData, index: number) => (
                    <LinkCard
                        data={{ linkCard: [post] }}
                        key={index}
                        minimalOnMobile={false}
                        componentsInSection={blogFeed.length}
                        asListItem={true}
                    />
                ))}
            </Columns>
            {blogFeed.length > 4 && (
                <Columns asList={true}>
                    {blogFeed.slice(4).map((post: LinkCardData, index: number) => (
                        <LinkCard
                            data={{ linkCard: [post] }}
                            key={index}
                            minimalOnMobile={false}
                            componentsInSection={blogFeed.length}
                            asListItem={true}
                            withoutText={true}
                        />
                    ))}
                </Columns>
            )}
        </Container>
    )
}

const Container = styled.div`
    > ul {
        padding: 0;
        margin: 2rem 0 0;
    }
`

export default BlogFeed
