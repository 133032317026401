import { DataLayer } from '../../utilities/general/data-layer'
import { graphql } from 'gatsby'
import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

/* eslint-disable no-useless-escape */
const emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

interface FormField {
    name: string
    value: string
}

interface Communication {
    value: boolean
    subscriptionTypeId: number
    text: string
}

interface InteractiveFormProps {
    data: {
        interactiveFormId: string
        choiceOneIntroductoryText: string
        choiceTwoIntroductoryText: string
        nextStepButtonText: string
        enterHintText: string
    }
    formData: any
}

const InteractiveForm = ({ data, formData }: InteractiveFormProps): JSX.Element => {
    const legalConsentCheckboxes = JSON.parse(
        formData.metaData.find((d: any) => d.name === 'legalConsentOptions').value
    ).communicationConsentCheckboxes
    const initialLegalConsentState = legalConsentCheckboxes.map((checkbox: any) => ({
        value: false,
        subscriptionTypeId: checkbox.communicationTypeId,
        text: checkbox.label,
    }))

    const [isInputFocused, setIsInputFocused] = useState(false)

    const inputs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]

    const eventListener = (event: any) => {
        setIsInputFocused(inputs.some(({ current }: any) => current === event.target))
    }

    useEffect(() => {
        if (!document || !document.addEventListener) {
            return
        }

        document.addEventListener('focusin', eventListener, false)

        return () => {
            document.removeEventListener('focusin', eventListener, false)
        }
    }, [])

    const [currentStep, setCurrentStep] = useState(0)
    const [wasOptionOneChosen, setWasOptionOneChosen] = useState(false)
    const [formState, setFormState] = useState<FormField[]>([])
    const [currentInputValue, setCurrentInputValue] = useState('')
    const [validationStatus, setValidationStatus] = useState('')
    const [legalConsent, setLegalConsent] = useState<Communication[]>(initialLegalConsentState)

    function updateFormState(name: string, value: string) {
        setFormState([...formState, { name, value }])

        if (currentStep === 0) {
            setWasOptionOneChosen(value === formData.formFieldGroups[0].fields[0].options[0].value)
        }

        setCurrentInputValue('')
        setValidationStatus('')

        // Very ugly workaround for Safari bug here, would love to do this differently
        if (
            inputs[currentStep] &&
            window &&
            window.innerWidth > 1024 &&
            (navigator.userAgent.indexOf('Chrome') > -1 || navigator.userAgent.indexOf('Firefox') > -1)
        ) {
            ;(inputs[currentStep].current as unknown as HTMLElement).focus({ preventScroll: true })
        }

        setCurrentStep(currentStep + 1)
    }

    function updateLegalConsent(id: number, newValue: boolean): void {
        setLegalConsent(
            legalConsent.map((checkbox: any) => {
                if (checkbox.subscriptionTypeId === id) {
                    return {
                        ...checkbox,
                        value: newValue,
                    }
                }

                return checkbox
            })
        )
    }

    function handleInput(type: string, value: string) {
        setCurrentInputValue(value)

        if (type === 'email') {
            setValidationStatus(emailRegex.test(value) ? 'validated' : '')
        } else if (value) {
            setValidationStatus('validated')
        } else {
            setValidationStatus('')
        }
    }

    function handleEnterPress(name: string, value: string) {
        if (validationStatus === 'validated') {
            updateFormState(name, value)
        } else {
            setValidationStatus('error')
        }
    }

    function submitForm() {
        fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${formData.portalId}/${formData.guid}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                submittedAt: Date.now(),
                fields: formState,
                context: {
                    pageUri: window.location.href,
                },
                legalConsentOptions: {
                    consent: {
                        consentToProcess: true,
                        text: legalConsent[0].text,
                        communications: legalConsent,
                    },
                },
            }),
        }).then(() => {
            DataLayer.push({
                event: 'forms.submitted',
                formType: 'offer',
                nameForm: formData.guid,
                Options: undefined,
                Status: undefined,
                userId: undefined,
                orderId: undefined,
            })
        })

        setCurrentStep(currentStep + 1)
    }

    return (
        <FormContainer transformValue={`translateX(-${currentStep * 100}%) translateZ(0)`}>
            <FirstChoiceContainer isVisible={currentStep === 1}>
                <div>
                    <h2>{formData.formFieldGroups[0].fields[0].label}</h2>
                    <ul>
                        {formData.formFieldGroups[0].fields[0].options.slice(0, 2).map((option: any, idx: number) => {
                            const text = option.label.split('|')

                            return (
                                <li key={idx}>
                                    <button
                                        onClick={() =>
                                            updateFormState(formData.formFieldGroups[0].fields[0].name, option.value)
                                        }
                                    >
                                        {text[2] && <p>{text[2]}</p>}
                                        <span>{text[0]}</span>
                                        <span>{text[1]}</span>
                                    </button>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </FirstChoiceContainer>
            {formData.formFieldGroups.slice(1).map((group: any, idx: number) => (
                <FormStepContainer key={idx} validationStatus={validationStatus}>
                    <div>
                        <div>
                            {idx === 0 && (
                                <TextContainer
                                    dangerouslySetInnerHTML={{
                                        __html: wasOptionOneChosen
                                            ? data.choiceOneIntroductoryText
                                            : data.choiceTwoIntroductoryText,
                                    }}
                                />
                            )}
                            {group.fields[0].fieldType === 'text' && (
                                <label>
                                    {group.fields[0].label}
                                    <input
                                        type="text"
                                        ref={inputs[idx]}
                                        value={currentInputValue}
                                        onChange={e => handleInput(group.fields[0].name, e.target.value)}
                                        onKeyDown={e =>
                                            e.key === 'Enter' &&
                                            handleEnterPress(group.fields[0].name, currentInputValue)
                                        }
                                    />
                                </label>
                            )}
                            <ButtonContainer
                                isAdditionalTextVisible={validationStatus === 'validated' && isInputFocused}
                            >
                                <Button
                                    onClick={() => updateFormState(group.fields[0].name, currentInputValue)}
                                    disabled={validationStatus !== 'validated'}
                                >
                                    {data.nextStepButtonText}
                                </Button>
                                <span>{data.enterHintText}</span>
                            </ButtonContainer>
                        </div>
                    </div>
                </FormStepContainer>
            ))}
            <LegalConsentContainer>
                <div>
                    <div>
                        {legalConsent.map((checkbox: any, idx: number) => (
                            <div key={idx}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={checkbox.value}
                                        onChange={e =>
                                            updateLegalConsent(checkbox.subscriptionTypeId, e.target.checked)
                                        }
                                    />
                                    <span dangerouslySetInnerHTML={{ __html: checkbox.text }} />
                                </label>
                            </div>
                        ))}
                        <Button onClick={() => submitForm()} disabled={!legalConsent[0].value}>
                            {formData.submitText}
                        </Button>
                    </div>
                </div>
            </LegalConsentContainer>
            <ThankYouContainer>
                <div>
                    <div dangerouslySetInnerHTML={{ __html: formData.inlineMessage }} />
                </div>
            </ThankYouContainer>
        </FormContainer>
    )
}

export default InteractiveForm

const maxMobileBreakpoint = '37.5rem'

const FormContainer = styled.div<{ transformValue: string }>`
    height: 22rem;
    max-width: 100%;
    position: relative;
    border-radius: 0.25rem;
    margin: 2.5rem auto 0;
    display: flex;
    overflow: hidden;

    & > * {
        flex-shrink: 0;
        height: 100%;
        width: 100%;
        -webkit-transform: ${props => props.transformValue};
        transform: ${props => props.transformValue};
        transition: transform 0.5s;
        padding: 0.1875rem;
        border-radius: 0.25rem;

        > div {
            height: 100%;
            background-color: var(--color-white);
        }
    }

    @media (max-width: ${maxMobileBreakpoint}) {
        height: auto;
        margin: 0 auto;
        align-items: center;
    }
`

const FirstChoiceContainer = styled.div<{ isVisible: boolean }>`
    h2 {
        text-align: center;
        padding: 1.5rem 0 2rem;
        margin: 0;
        font-size: var(--font-size-large);
        font-weight: var(--font-weight-medium);
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;

        li {
            display: flex;
            flex: 1;

            &:first-of-type {
                border-right: 0.0625rem solid var(--color-green);
            }

            &:last-of-type {
                border-left: 0.0625rem solid var(--color-green);
            }

            button {
                display: flex;
                flex-direction: column;
                align-content: stretch;
                flex: 1;
                width: 100%;
                background-color: transparent;
                color: var(--color-black);
                text-align: left;
                border: 0;
                margin: 0;
                padding: 0;
                cursor: pointer;

                p {
                    background-image: var(--gradient-green);
                    color: var(--color-white);
                    padding: 0.125rem 0.375rem;
                    margin: 0.5rem 1rem;
                    align-self: flex-end;
                    font-size: var(--font-size);
                }

                span {
                    display: block;

                    &:first-of-type {
                        flex: 1;
                        display: flex;
                        align-items: flex-end;
                        font-family: var(--font-headings);
                        font-size: var(--font-size-extra-large);
                        font-weight: var(--font-weight-bold);
                        line-height: var(--line-height-headings);
                        margin: 0 2rem 0.5rem 2rem;
                    }

                    &:last-of-type {
                        font-size: 1.25rem;
                        margin: 0.5rem 2rem 2rem 2rem;
                    }
                }

                &:hover {
                    background-image: var(--gradient-green);
                    color: var(--color-white);

                    p {
                        background: transparent;
                    }
                }
            }
        }

        @media (max-width: ${maxMobileBreakpoint}) {
            border-radius: 0.25rem;
            flex-direction: column;

            li {
                width: 100%;

                &:first-of-type {
                    border: 0;
                    border-bottom: 0.0625rem solid var(--color-green);
                }

                &:last-of-type {
                    border: 0;
                    border-top: 0.0625rem solid var(--color-green);
                }

                button {
                    padding: 2rem 0 1rem;

                    &:hover {
                        opacity: 0.7;
                        background-image: none;
                        color: var(--color-black);

                        p {
                            background-image: var(--gradient-green);
                        }
                    }
                }
            }
        }
    }
`

const FormStepContainer = styled.div<{ validationStatus: string }>`
    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    label {
        font-size: var(--font-size-large);
    }

    input[type='text'] {
        display: block;
        font-size: var(--font-size-large);
        padding: 1rem 2rem;
        border: 0.0625rem solid var(--color-grey);
        border-radius: 3rem;
        margin: 0.75rem 0;

        ${props =>
            props.validationStatus === 'validated' &&
            css`
                border-color: var(--color-green);
            `}

        ${props =>
            props.validationStatus === 'error' &&
            css`
                border-color: var(--color-red);
            `}

    &:focus {
            outline: none;
        }
    }

    @media (max-width: ${maxMobileBreakpoint}) {
        input[type='text'] {
            font-size: var(--font-size);
            padding: 0.5rem 1rem;
        }
    }
`

const TextContainer = styled.div`
    max-width: 27.5rem;

    > * {
        margin-top: 0;
    }

    h2,
    h3 {
        margin: 0 0 0.75rem;
    }
`

const ButtonContainer = styled.div<{ isAdditionalTextVisible: boolean }>`
    display: flex;

    span {
        opacity: 0;
        margin: 0 0 0 0.5rem;
        transition: opacity 0.15s ease;

        @media (max-width: ${maxMobileBreakpoint}) {
            display: none;
        }
    }

    ${props =>
        props.isAdditionalTextVisible &&
        css`
            span {
                opacity: 1;
            }
        `}
`

const Button = styled.button`
    background: var(--gradient-green);
    color: var(--color-white);
    font-size: 1rem;
    font-family: var(--font-headings);
    padding: 0.25rem 0.5rem;
    border: 0;
    border-radius: 0.25rem;
    margin: 0;
    cursor: pointer;

    &[disabled] {
        opacity: 0.6;
        cursor: default;
    }
`

const LegalConsentContainer = styled.div`
    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > div {
            width: 80%;

            @media (max-width: ${maxMobileBreakpoint}) {
                width: 100%;
            }
        }

        label {
            margin: 0 0 1rem;
            display: flex;
            align-items: flex-start;

            input {
                flex-shrink: 0;
                min-height: 12px;
                min-width: 12px;
                margin: 0.5rem 0.5rem 0 0;
            }

            p {
                margin: 0;
            }
        }
    }
`

const ThankYouContainer = styled.div`
    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > div {
            width: 80%;

            * {
                font-size: 1.25rem;
            }
        }
    }
`

export const query = graphql`
    fragment InteractiveFormFragment on Craft_components_interactiveForm_BlockType {
        interactiveFormId
        choiceOneIntroductoryText
        choiceTwoIntroductoryText
        nextStepButtonText
        enterHintText
    }

    fragment InteractiveFormReusableComponentFragment on Craft_componentType_interactiveForm_BlockType {
        interactiveFormId
        choiceOneIntroductoryText
        choiceTwoIntroductoryText
        nextStepButtonText
        enterHintText
    }
`
