import { BijtellingResults } from '@mistergreen/shared/utilities/bijtelling'
import formatWithCurrencySymbol from './format-with-currency-symbol'

export const getBijtellingSavingsText = (rawSavingsText: string, bijtellingResults: BijtellingResults): string => {
    const bracketOne = formatWithCurrencySymbol(
        bijtellingResults.bracketOneResultNextYear - bijtellingResults.bracketOneResultCurrentYear,
        'nl-NL',
        { hardcodeCurrency: 'EUR', displayAsFloat: false }
    )

    const bracketTwo = formatWithCurrencySymbol(
        bijtellingResults.bracketTwoResultNextYear - bijtellingResults.bracketTwoResultCurrentYear,
        'nl-NL',
        { hardcodeCurrency: 'EUR', displayAsFloat: false }
    )

    return rawSavingsText.replace(
        /\{\{\s*taxBracket(\w+)Saving\s*\}\}/g,
        (_, group) => `<span>${group === 'One' ? bracketOne : bracketTwo}</span>`
    )
}
