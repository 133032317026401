import { CraftEntry, CraftGlobalSet } from './types'
import { extractGlobalSet } from '../craft-data/extract-data'
import { LinkCardData } from '../../components/craft-cms/link-card'

interface GetBlogFeedParam {
    blogEntries: CraftEntry[]
    globalSets: CraftGlobalSet[]
}

export interface BlogPostData extends LinkCardData {
    pageId: string
    categories?: {
        title: string
    }[]
}

export function getBlogFeed({ blogEntries, globalSets }: GetBlogFeedParam): BlogPostData[] {
    const { linkTextOnBlogFeedCards } = extractGlobalSet(globalSets, 'sitewideSettings')

    return blogEntries.map((entry: CraftEntry) => {
        return {
            pageId: entry.id,
            image: entry.featuredImage,
            alt: entry.alt,
            heading: entry.title,
            text: entry.excerpt,
            linkArray: [
                {
                    text: linkTextOnBlogFeedCards,
                    entry: [entry],
                },
            ],
            categories: entry.categories,
        }
    })
}

interface GetPostsInCategoriesParam {
    allBlogPosts: BlogPostData[]
    selectedCategory: { title: string }[] | undefined
}

export function getPostsInCategories({ allBlogPosts, selectedCategory }: GetPostsInCategoriesParam): BlogPostData[] {
    if (!selectedCategory?.length) {
        return allBlogPosts
    }

    const includedCategories = selectedCategory?.map(({ title }) => title)

    return allBlogPosts.filter(
        ({ categories }) => !!categories && categories.some(({ title }) => includedCategories.includes(title))
    )
}
