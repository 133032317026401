import useIntersectionObserver, { IntersectionObserverOptions } from './use-intersection-observer'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { loadScript } from '../general/loadScript'

type UseLazyLoadedScriptData = [Dispatch<SetStateAction<Element | null>>, boolean, boolean]

function useLazyLoadedScript(
    src: string,
    intersectionObserverOptions: IntersectionObserverOptions = {}
): UseLazyLoadedScriptData {
    const [state, setState] = useState({ loaded: false, error: false })

    const [setNode, entry, didObserverError] = useIntersectionObserver(intersectionObserverOptions)

    useEffect(() => {
        if ((entry as IntersectionObserverEntry).isIntersecting || didObserverError) {
            let hasBeenTerminated = false

            loadScript(src)
                .then(() => {
                    if (!hasBeenTerminated) {
                        setState({ loaded: true, error: false })
                    }
                })
                .catch(() => {
                    if (!hasBeenTerminated) {
                        setState({ loaded: true, error: true })
                    }
                })
            return () => {
                hasBeenTerminated = true
            }
        }
    }, [src, entry, didObserverError])

    return [setNode, state.loaded, state.error]
}

export default useLazyLoadedScript
