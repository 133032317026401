import React from 'react'
import { TableMobileLabelData } from './MobileTable'

type TableRowProps = {
    row: TableMobileLabelData
}
const TableMobileRow = ({ row }: TableRowProps): JSX.Element => {
    const { label, value, description } = row

    let rowHeading = <h4>{label}</h4>

    if (description) {
        rowHeading = (
            <details>
                <summary>
                    <h4>{label}</h4>
                </summary>
                <div>
                    {description.map((descriptionLabel, index) => (
                        <p key={`${label}-${index}`}>{descriptionLabel}</p>
                    ))}
                </div>
            </details>
        )
    }

    return (
        <div>
            {rowHeading}
            <div>{value}</div>
        </div>
    )
}
export default TableMobileRow
