import query from './query'

export interface UserProfile {
    firstName: string
    referralLink: string
}

export async function getUserProfile(): Promise<UserProfile> {
    let userProfile = { firstName: '', referralLink: '' }

    await query('{myUserProfile {id referralCode firstName}}').then(referralData => {
        if (referralData?.myUserProfile) {
            userProfile = {
                firstName: referralData.myUserProfile.firstName,
                referralLink: `https://mstr.gr/${referralData.myUserProfile.referralCode}`,
            }
        }
    })

    return userProfile
}

export enum SignUpStatus {
    Pending,
    EmailAlreadyKnown,
    Success,
}

export async function signUpUser(email: string): Promise<SignUpStatus> {
    let status = SignUpStatus.Pending

    const validateQuery = await query(`{ validateSignupUser(emailAddress: "${email}") }`)

    if (!validateQuery?.validateSignupUser) {
        status = SignUpStatus.EmailAlreadyKnown
        query(`mutation { requestLoginEmail(email: "${email}", path: "${window.location}") }`)
    } else {
        const signupMutation = await query(`mutation { signupUser(emailAddress: "${email}") { userId token } }`)

        if (signupMutation?.signupUser?.token) {
            localStorage.setItem('token', signupMutation.signupUser.token)
            status = SignUpStatus.Success
        }
    }

    return status
}
