export function executeOnce<Result = any>(fn: () => Result): () => Result {
    let hasBeenExecuted = false
    let fnResult: Result
    return () => {
        if (!hasBeenExecuted) {
            hasBeenExecuted = true
            fnResult = fn()
        }
        return fnResult
    }
}
