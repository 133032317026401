import { graphql } from 'gatsby'
import Image, { ImageData, ImageVersion } from './image'
import Link, { LinkData } from '../core/link'
import { primaryButton, whiteOpaqueButton } from './_shared-styles'
import React, { useRef } from 'react'
import styled from 'styled-components'
import { typographyMaxWidthBreakpoint } from '../core/global-styles'

export interface TextOnImageData {
    image: ImageData[]
    alt?: string
    textOverImage: string
    optionalButtons: LinkData[]
    optionalBottomButtonText: string
}

export interface TextOnImageProps {
    data: TextOnImageData
}

const TextOnImage = ({ data }: TextOnImageProps): JSX.Element => {
    const outerElement = useRef(null)
    const handleBottomClick = () => {
        const element = outerElement.current as unknown as HTMLElement
        const { bottom } = element.getBoundingClientRect()
        window.scrollTo({ top: window.scrollY + bottom })
    }

    return (
        <OuterContainer ref={outerElement}>
            <Image data={data} version={ImageVersion.FullWidth} />
            <TextContainer>
                <TextWrapper dangerouslySetInnerHTML={{ __html: data.textOverImage }} />
                {!!data.optionalButtons.length && (
                    <ButtonGroup>
                        {data.optionalButtons.map((button: LinkData, index: number) => {
                            return button.isPrimaryButton ? (
                                <PrimaryLinkContainer key={index}>
                                    <Link data={button} />
                                </PrimaryLinkContainer>
                            ) : (
                                <LinkContainer key={index}>
                                    <Link data={button} />
                                </LinkContainer>
                            )
                        })}
                    </ButtonGroup>
                )}
            </TextContainer>
            {data.optionalBottomButtonText && (
                <BottomButtonContainer>
                    <BottomButton onClick={handleBottomClick}>{data.optionalBottomButtonText}</BottomButton>
                </BottomButtonContainer>
            )}
        </OuterContainer>
    )
}

const OuterContainer = styled.div`
    position: relative;
    padding: 10rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    > picture,
    > picture > img {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    > picture > img {
        filter: brightness(0.6);
        object-fit: cover;
    }
`

const TextContainer = styled.div`
    position: relative;
    text-align: center;
`

const TextWrapper = styled.div`
    max-width: 40rem;
    color: var(--color-white);
    margin: 0 auto;

    > :first-child {
        margin-top: 0;
    }

    > :last-child {
        margin-bottom: 0;
    }

    h1 {
        font-size: 3.25rem;
        font-weight: var(--font-weight-medium);
        margin: 0;

        & + * {
            margin-top: 0.5rem;
        }

        @media (max-width: ${typographyMaxWidthBreakpoint}) {
            font-size: var(--font-size-extra-extra-large);
        }
    }

    p {
        font-size: var(--font-size-medium);
    }
`

const ButtonGroup = styled.div`
    max-width: 40rem;
    text-align: left;
    padding: 1rem 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`

const LinkContainer = styled.div`
    ${whiteOpaqueButton}
    margin: 1rem .5rem 0;
`

const PrimaryLinkContainer = styled.div`
    ${primaryButton}
    margin: 1rem .5rem 0;
`

const BottomButtonContainer = styled.div`
    width: 100%;
    position: absolute;
    top: calc(100% - 8rem);
    left: 0;
`

const BottomButton = styled.button`
    display: block;
    width: 16rem;
    background-color: rgba(0, 0, 0, 0.1);
    color: var(--color-grey-extra-light);
    font-size: var(--font-size);
    text-align: center;
    padding: 0.75rem 3rem;
    border: 0.125rem solid rgba(0, 0, 0, 0.4);
    border-radius: 2rem;
    margin: 0 auto;
    cursor: pointer;

    &::after {
        content: '';
        height: 0.625rem;
        width: 0.75rem;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' aria-hidden='true' height='8' width='12'%3E%3Cpath d='M1.5.855c.45 0 .75.15 1.05.45L6 4.755l3.45-3.45c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1l-4.5 4.5c-.6.6-1.5.6-2.1 0l-4.5-4.5c-.6-.6-.6-1.5 0-2.1.3-.3.6-.45 1.05-.45z'%3E%3C/path%3E%3C/svg%3E");
        background-repeat: no-repeat;
        position: absolute;
        top: calc(100% + 1rem);
        left: calc(50% - 0.375rem);
        cursor: pointer;
    }

    &:focus {
        border: 2px solid #fff;
        outline: none;
    }
`

export default TextOnImage

export const query = graphql`
    fragment TextOnImageFragment on Craft_components_textOnImage_BlockType {
        image: backgroundImage {
            filename
            dateUpdated
            height
            width
            hasFocalPoint
            focalPoint
        }
        alt: backgroundAlt
        textOverImage
        optionalButtons {
            ... on Craft_optionalButtons_internalLink_BlockType {
                text
                entry {
                    typeHandle
                    slug
                }
                isPrimaryButton
            }
            ... on Craft_optionalButtons_externalLink_BlockType {
                text
                linkUrl
                isPrimaryButton
            }
        }
        optionalBottomButtonText
    }

    fragment TextOnImageReusableComponentFragment on Craft_componentType_textOnImage_BlockType {
        image: backgroundImage {
            filename
            dateUpdated
            height
            width
            hasFocalPoint
            focalPoint
        }
        alt: backgroundAlt
        textOverImage
        optionalButtons {
            ... on Craft_optionalButtons_internalLink_BlockType {
                text
                entry {
                    typeHandle
                    slug
                }
                isPrimaryButton
            }
            ... on Craft_optionalButtons_externalLink_BlockType {
                text
                linkUrl
                isPrimaryButton
            }
        }
        optionalBottomButtonText
    }
`
