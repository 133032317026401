import React from 'react'
import styled, { css } from 'styled-components'
import Icon from './icon'

export interface SocialMediaShareProps {
    shareViaEmailText: string
    shareViaWhatsAppText: string
    shareViaTwitterText: string
    shareViaLinkedInText: string
    emailSubject: string
    description: string
    link: string
}

const SocialMediaShare = (data: SocialMediaShareProps): JSX.Element => {
    const emailHref = `mailto:?subject=${encodeURIComponent(data.emailSubject)}&body=${encodeURIComponent(
        data.description + ' ' + data.link
    )}`
    const whatsAppHref = `https://api.whatsapp.com/send?text=${encodeURIComponent(data.description + ' ' + data.link)}`
    const twitterHref = `https://twitter.com/share?text=${encodeURIComponent(
        data.description
    )}&url=${encodeURIComponent(data.link)}`
    const linkedInHref = `https://www.linkedin.com/shareArticle/?mini=true&url=${encodeURIComponent(data.link)}`

    return (
        <Container>
            <Item channel="email">
                <a href={emailHref} target="_blank" rel="noopener noreferrer">
                    <Icon type="email" />
                    {data.shareViaEmailText}
                </a>
            </Item>
            <Item channel="whatsApp">
                <a href={whatsAppHref} target="_blank" rel="noopener noreferrer">
                    <Icon type="info" />
                    {data.shareViaWhatsAppText}
                </a>
            </Item>
            <Item channel="twitter">
                <a href={twitterHref} target="_blank" rel="noopener noreferrer">
                    <Icon type="twitter" />
                    {data.shareViaTwitterText}
                </a>
            </Item>
            <Item channel="linkedIn">
                <a href={linkedInHref} target="_blank" rel="noopener noreferrer">
                    <Icon type="linkedin" />
                    {data.shareViaLinkedInText}
                </a>
            </Item>
        </Container>
    )
}

const Container = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
`

const Item = styled.li<{ channel: string }>`
    width: 100%;
    margin: 1rem 0 0;

    @media (min-width: 30rem) {
        width: auto;
        margin: 1rem 1rem 0 0;
    }

    &:last-of-type {
        margin: 1rem 0 0;
    }

    a {
        height: 2.25rem;
        color: var(--color-white);
        font-size: var(--font-size-small);
        padding: 0.25rem 1rem;
        border: 0.125rem solid var(--color-white);
        border-radius: 0.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.15s ease-in-out;

        &:hover {
            background-color: var(--color-white);
            color: var(--color-green-dark);
        }
    }

    svg {
        margin: 0 0.5rem 0 0;
    }

    ${props =>
        props.channel === 'email' &&
        css`
            svg {
                height: 1.375rem;
            }
        `}

    ${props =>
        props.channel === 'whatsApp' &&
        css`
            svg {
                height: 1.375rem;
            }
        `}

  ${props =>
        props.channel === 'twitter' &&
        css`
            svg {
                height: 1.125rem;
            }
        `}

  ${props =>
        props.channel === 'linkedIn' &&
        css`
            svg {
                height: 1.625rem;
            }
        `}
`

export default SocialMediaShare
