import React from 'react'

type TableRowProps = {
    row: { label: string; description?: string[]; values: string[] }
}
const TableRow = ({ row }: TableRowProps): JSX.Element => {
    const { label, values, description } = row
    let rowHeading = <th>{label}</th>

    if (description) {
        rowHeading = (
            <th>
                <details>
                    <summary>{label}</summary>
                    <div>
                        {description.map((descriptionLabel, index) => (
                            <p key={`${label}-${index}`}>{descriptionLabel}</p>
                        ))}
                    </div>
                </details>
            </th>
        )
    }

    return (
        <tr>
            {rowHeading}
            {values.map((value, index) => (
                <td key={index}>
                    <div>{value}</div>
                </td>
            ))}
        </tr>
    )
}
export default TableRow
