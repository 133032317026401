import React from 'react'
import styled, { css } from 'styled-components'

const FormLoadingPlaceholder = (): JSX.Element => (
    <LoadingContainer>
        <HalfSizeFieldLoadingPlaceholder />
        <HalfSizeFieldLoadingPlaceholder />
        <FullSizeFieldLoadingPlaceholder />
        <FullSizeFieldLoadingPlaceholder />
        <FullSizeFieldLoadingPlaceholder />
        <TextLoadingContainer>
            <TextLineLoadingPlaceholder />
            <TextLineLoadingPlaceholder />
            <TextLineLoadingPlaceholder />
        </TextLoadingContainer>
        <ButtonLoadingPlaceholder />
    </LoadingContainer>
)

export default FormLoadingPlaceholder

const sharedPlaceholderStyles = css`
    position: relative;
    background: rgba(207, 218, 234, 0.75);
    overflow: hidden;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -30%;
        bottom: 0;
        left: -30%;
        background: linear-gradient(
                to right,
                rgba(255, 255, 255, 0) 42%,
                rgba(255, 255, 255, 0.25) 50%,
                rgba(255, 255, 255, 0) 58%
            )
            50% 50%;
        animation: loading-animation 1.3s linear infinite;
    }
`

const LoadingContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    transition: opacity 0.4s ease-in-out, height 0.01s 0.4s linear;

    @keyframes loading-animation {
        0% {
            transform: translate3d(-30%, 0, 0);
        }

        100% {
            transform: translate3d(30%, 0, 0);
        }
    }
`

const HalfSizeFieldLoadingPlaceholder = styled.div`
    height: 2.25rem;
    ${sharedPlaceholderStyles}
`

const FullSizeFieldLoadingPlaceholder = styled.div`
    height: 2.25rem;
    grid-column: 1 / span 2;
    ${sharedPlaceholderStyles}
`

const TextLoadingContainer = styled.div`
    grid-column: 1 / span 2;
    padding: 0.875rem 0;
`

const TextLineLoadingPlaceholder = styled.div`
    height: 1rem;
    width: 70%;
    ${sharedPlaceholderStyles}

    &:nth-child(2) {
        width: 85%;
    }

    &:nth-child(3) {
        width: 52%;
    }

    & + & {
        margin-top: 0.5rem;
    }
`

const ButtonLoadingPlaceholder = styled.div`
    height: 2.875rem;
    grid-column: 1 / span 2;
    border-radius: 2rem;
    ${sharedPlaceholderStyles}
`
