import React from 'react'
import styled from 'styled-components'

import { defaultButton, primaryButton } from './_shared-styles'
import Image, { ImageData, ImageVersion } from './image'
import Link, { LinkData } from '../core/link'
import { graphql } from 'gatsby'

export interface CallToActionData {
    optionalTitle: string
    optionalSubtitle: string
    image: ImageData[]
    alt?: string
    buttonGroup?: LinkData[]
}

export interface CallToActionProps {
    data: CallToActionData
    componentsInSection: number
}

const CallToAction = ({ data, componentsInSection }: CallToActionProps): JSX.Element => (
    <div>
        {(data.optionalTitle || data.optionalSubtitle) && (
            <TitleContainer>
                {data.optionalTitle && <Title>{data.optionalTitle}</Title>}
                {data.optionalSubtitle && <Subtitle>{data.optionalSubtitle}</Subtitle>}
            </TitleContainer>
        )}
        {!!data.image && !!data.image.length && (
            <Image data={data} version={componentsInSection === 1 ? ImageVersion.Large : ImageVersion.Medium} />
        )}
        <ButtonGroup>
            {!!data.buttonGroup &&
                data.buttonGroup.map((button, index) => {
                    return button.isPrimaryButton ? (
                        <PrimaryLinkContainer key={index}>
                            <Link data={button} />
                        </PrimaryLinkContainer>
                    ) : (
                        <LinkContainer key={index}>
                            <Link data={button} />
                        </LinkContainer>
                    )
                })}
        </ButtonGroup>
    </div>
)

const titleBreakpointMinWidth = '38rem'

const TitleContainer = styled.div`
    @media (min-width: ${titleBreakpointMinWidth}) {
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
`

const Title = styled.h2`
    background-color: var(--color-black-light);
    color: var(--color-white);
    padding: 0.25rem var(--gutter-width);
    margin: 0 calc(var(--gutter-width) * -1);

    @media (min-width: ${titleBreakpointMinWidth}) {
        height: 2.5rem;
        display: inline-block;
        position: relative;
        font-size: 1.25rem; /* Don't use variable here, needs to be static across viewport sizes. */
        padding: 0.365rem 1.75rem;
        margin: 0 3rem 0 0;

        &::after {
            content: '';
            height: 100%;
            width: 1.5rem;
            position: absolute;
            top: 0;
            left: 100%;
            border-right: 1.5rem solid transparent;
            border-bottom: 2.5rem solid var(--color-black-light);
        }
    }
`

const Subtitle = styled.p`
    background-color: var(--color-green);
    color: var(--color-white);
    padding: 0.25rem var(--gutter-width);
    margin: 0 calc(var(--gutter-width) * -1);

    @media (min-width: ${titleBreakpointMinWidth}) {
        height: 2.125rem;
        display: inline-block;
        position: relative;
        font-size: 1rem; /* Don't use variable here, needs to be static across viewport sizes. */
        font-weight: var(--font-weight-headings);
        padding: 0.25rem 1.25rem;
        margin: 0 0 0 1rem;

        &::before {
            content: '';
            height: 100%;
            width: 1.5rem;
            position: absolute;
            top: 0;
            right: 100%;
            border-left: 1.5rem solid transparent;
            border-bottom: 2.125rem solid var(--color-green);
        }
    }
`

const ButtonGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`

const LinkContainer = styled.div`
    ${defaultButton}
    margin: 1rem .5rem 0;
`

const PrimaryLinkContainer = styled.div`
    ${primaryButton}
    margin: 1rem .5rem 0;
`

export default CallToAction

export const query = graphql`
    fragment CallToActionFragment on Craft_components_callToAction_BlockType {
        optionalTitle
        optionalSubtitle
        image: optionalImage {
            filename
            dateUpdated
            height
            width
        }
        alt: optionalAltText
        buttonGroup {
            ... on Craft_buttonGroup_internalLink_BlockType {
                text
                entry {
                    typeHandle
                    slug
                }
                isPrimaryButton
            }
            ... on Craft_buttonGroup_externalLink_BlockType {
                text
                linkUrl
                isPrimaryButton
            }
        }
    }

    fragment CallToActionReusableComponentFragment on Craft_componentType_callToAction_BlockType {
        optionalTitle
        optionalSubtitle
        image: optionalImage {
            filename
            dateUpdated
            height
            width
        }
        alt: optionalAltText
        buttonGroup {
            ... on Craft_buttonGroup_internalLink_BlockType {
                text
                entry {
                    typeHandle
                    slug
                }
                isPrimaryButton
            }
            ... on Craft_buttonGroup_externalLink_BlockType {
                text
                linkUrl
                isPrimaryButton
            }
        }
    }
`
