import React from 'react'
import styled, { css } from 'styled-components'
import InnerTable from './InnerTable'
import MobileTable from './MobileTable'
export type TableProps = {
    headers: string[]
    values: { label: string; values: string[]; description?: string[] }[]
}

const Table = ({ headers, values }: TableProps): JSX.Element => {
    return (
        <OuterContainer>
            <>
                <SpecificationsTable>
                    <InnerTable headers={headers} values={values} />
                </SpecificationsTable>
                <MobileSpecificationsTable>
                    {headers.map((header, index) => (
                        <MobileEditionCard key={index} isPreferredEdition={false}>
                            <MobileTable
                                key={index}
                                index={index}
                                header={header}
                                data={values.map(v => ({
                                    value: v.values[index],
                                    description: v.description,
                                    label: v.label,
                                }))}
                            />
                        </MobileEditionCard>
                    ))}
                </MobileSpecificationsTable>
            </>
        </OuterContainer>
    )
}
export default Table

const OuterContainer = styled.div`
    --color-table-border: #e2e2e2;
    --color-table-heading: #eee;
    margin-bottom: var(--vertical-spacing);

    @media (max-width: 60rem) {
        margin-bottom: var(--spacing-elements-less);
    }
`
const SpecificationsTable = styled.table`
    table-layout: fixed;
    width: 100%;
    position: relative;
    border-collapse: collapse;
    box-shadow: var(--box-shadow-less);

    thead {
        th {
            position: sticky;
            top: -0.125rem;
            background-color: var(--color-table-heading);

            &:first-child {
                font-size: var(--font-size-large);
                font-weight: 600;
                text-align: left;
                vertical-align: middle;
            }

            &[data-is-preferred='true'] {
                border-top-color: var(--color-green);
            }

            > a {
                font-size: var(--font-size);
            }
        }
    }

    tbody {
        th {
            text-align: left;

            details {
                > div {
                    font-size: var(--font-size);
                    font-weight: initial;
                }
            }
        }

        td {
            text-align: right;
        }

        tr:last-child td[data-is-preferred='true'] {
            border-bottom-color: var(--color-green);
        }
    }

    th,
    td {
        font-size: var(--font-size-medium);
        vertical-align: top;
        padding: 0.55rem 1rem;
        border: 0.125rem solid var(--color-table-border);

        :not(:last-child):not([data-is-preferred='true']) {
            border-right: 0;
        }

        &[data-is-preferred='true'] {
            border-left-color: var(--color-green);
            border-right-color: var(--color-green);
        }
    }

    @media (max-width: 60rem) {
        display: none;
    }
`

const MobileSpecificationsTable = styled.div`
    @media (min-width: 42rem) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;

        > * {
            width: calc(50% - 0.75rem);

            &:nth-child(2n-1):not(:last-child) {
                margin-right: 1.5rem;
            }
        }
    }

    @media (min-width: 60.0625rem) {
        display: none;
    }
`

const MobileEditionCard = styled.div<{ isPreferredEdition: boolean }>`
    position: relative;
    text-align: center;
    border: 0.125rem solid var(--color-table-border);
    box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.15);

    &:not(:first-child) {
        margin-top: 1.5rem;
    }

    @media (min-width: 42rem) {
        &:nth-child(2) {
            margin-top: 0;
        }
    }

    ${props =>
        props.isPreferredEdition &&
        css`
            border-color: var(--color-green);
        `}

    > header {
        position: sticky;
        top: 0;
        background-color: var(--color-table-heading);
        padding: 1.5rem;

        h3 {
            margin: 0;
        }
    }

    > div {
        padding: 1.5rem;

        &:not(:last-child) {
            border-bottom: 0.125rem solid var(--color-table-border);
        }

        h4,
        p {
            margin: 0;
        }

        details {
            summary {
                &:focus {
                    outline: none;
                }

                > * {
                    display: inline;
                }
            }

            > div {
                font-size: var(--font-size-small);
                padding: 0 0 0.75rem;
                border-bottom: 0.0625rem solid var(--color-grey-light);
                margin: 0 0 0.75rem;
            }
        }
    }
`
