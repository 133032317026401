import styled from 'styled-components'

export const WaveBackground = styled.div`
    min-height: 31rem;
    position: relative;
    background-image: var(--gradient-green);
    color: var(--color-white);
    padding: 10.5rem 0 2rem;
    margin: var(--vertical-spacing) 0 0;

    &::before {
        content: '';
        height: 10.5rem;
        width: 100%;
        position: absolute;
        top: -0.0625rem; // Fixes the parent's gradient background occasionally being visible above the ::before-element background-image on zoom out.
        left: 0;
        z-index: 1;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 600' preserveAspectRatio='none'%3E%3Cpath d='M0%2C343c0%2C0 191.266%2C-188.125 400%2C0c208.734%2C188.125 370.649%2C52.478 400%2C0c0.477%2C1.24 0%2C257 0%2C257l-800%2C0l0%2C-257Z' style='fill:%23fff%3B'/%3E%3C/svg%3E");
        background-size: 100% 100%;
        transform: rotate(180deg) scaleX(-1);
    }

    h2 {
        font-weight: var(--font-weight-medium);
    }

    > h2 {
        font-size: var(--font-size-extra-extra-large);
        font-weight: var(--font-weight-bold);
    }

    ul li,
    ol li,
    a {
        color: var(--color-white);
    }

    a {
        font-weight: var(--font-weight-medium);

        &:not([class]) {
            text-decoration: underline;
        }
    }

    blockquote {
        border-color: var(--color-white);
    }

    [class*='OuterLink'] a {
        background-image: var(--gradient-red);
        text-decoration: none;
        border: 0;
    }
`

export default WaveBackground
