import Banner from '../craft-cms/banner'
import BijtellingCalculator from '../craft-cms/bijtelling-calculator'
import BlogCategoryFeed from '../craft-cms/blog-category-feed'
import BlogFeed from '../craft-cms/blog-feed'
import { BlogPostData } from '../../utilities/general/blog-feed'
import CallToAction from '../craft-cms/call-to-action'
import CallToActionHero from '../craft-cms/call-to-action-hero'
import { CarEditionSpecificationLabels } from '../../utilities/car-edition-specification/labels'
import CarEditionSpecifications from '../craft-cms/car-edition-specifications'
import Columns from '../ui/columns'
import { ConfigurationModel } from '../../utilities/create-pages/fetch-model-information'
import { CraftGlobalSet } from '../../utilities/general/types'
import DefaultBackground from '../background/default-background'
import DescriptionList from '../craft-cms/description-list'
import FAQCollection from '../craft-cms/faq-collection'
import Form from '../craft-cms/form'
import FullScreenBackground from '../background/full-screen-background'
import FullWidthBackground from '../background/full-width-background'
import { graphql } from 'gatsby'
import IFrame from '../craft-cms/iframe'
import Image, { ImageVersion } from '../craft-cms/image'
import InteractiveForm from '../craft-cms/interactive-form'
import LinkBanner from '../craft-cms/link-banner'
import LinkCard from '../craft-cms/link-card'
import List from '../craft-cms/list'
import processChildComponentName from '../../utilities/craft-data/process-child-component-name'
import React from 'react'
import ReferralCode from '../craft-cms/referral-code'
import styled, { StyledComponent, css } from 'styled-components'
import Text from '../craft-cms/text'
import TextOnImage from '../craft-cms/text-on-image'
import WaveBackground from '../background/wave-background'
import TrustpilotCarousel from '../craft-cms/trustpilot-carousel'
import LeaseComparisonTable from '../craft-cms/lease-comparison-table'

interface SectionProps {
    blogFeed: BlogPostData[]
    carEditionSpecificationLabels?: CraftGlobalSet
    configurationModels?: ConfigurationModel[]
    data: any
    formData?: any[]
    hideHeadingOnMobile?: boolean
    minimalOnMobile?: boolean
    pageId?: string
    sectionIndex: number
    trustpilotGlobals?: CraftGlobalSet
}

export interface ContainerMap {
    [key: string]: StyledComponent<'div', any, Record<string, unknown>, never>
}

const containerMap: ContainerMap = {
    defaultBackground: DefaultBackground,
    fullScreen: FullScreenBackground,
    fullWidth: FullWidthBackground,
    greenWave: WaveBackground,
}

export interface ComponentMap {
    [key: string]: ({ data }: any) => JSX.Element
}

const componentMap: ComponentMap = {
    banner: Banner,
    bijtellingCalculator: BijtellingCalculator,
    blogCategoryFeed: BlogCategoryFeed,
    callToAction: CallToAction,
    callToActionHero: CallToActionHero,
    carEditionSpecifications: CarEditionSpecifications,
    descriptionList: DescriptionList,
    faqCollection: FAQCollection,
    form: Form,
    image: Image,
    linkCard: LinkCard,
    list: List,
    text: Text,
    textOnImage: TextOnImage,
    linkBanner: LinkBanner,
    referralCode: ReferralCode,
    iframe: IFrame,
    interactiveForm: InteractiveForm,
    trustpilotCarousel: TrustpilotCarousel,
}

const Section = ({
    blogFeed,
    carEditionSpecificationLabels,
    configurationModels = [],
    data,
    formData = [],
    hideHeadingOnMobile = false,
    minimalOnMobile = false,
    pageId,
    sectionIndex,
    trustpilotGlobals,
}: SectionProps): JSX.Element => {
    const Container = containerMap[data.background]

    // Generally we want to just let CSS do its flexible magic.
    // Some specific combination of components needs some nudging though.
    // Please limit the amount of component combinations
    // for which we manually override the default layout.
    const components = data.children.map((child: any) => processChildComponentName(child))

    // We want to render the section as a list only if all children are LinkCard's
    const renderAsList = !components.some((child: any) => child !== 'linkCard')

    let layoutOverride = ''
    if (
        components.length === 2 &&
        components[0] === 'text' &&
        (components[1] === 'callToAction' || components[1] === 'interactiveForm')
    ) {
        layoutOverride = 'wideRight'
    } else if (
        components.length === 2 &&
        (components.includes('descriptionList') || components.includes('form')) &&
        components.includes('image')
    ) {
        layoutOverride = 'elementOnImage'
    } else if (
        components.length === 2 &&
        (components.includes('descriptionList') || components.includes('text')) &&
        components.includes('iframe')
    ) {
        layoutOverride = 'twoEqualColumns'
    } else if (components.length > 3 && renderAsList) {
        layoutOverride = 'cardList'
    }

    const topBanner = sectionIndex === 0 && components[0] === 'banner'

    return (
        <Container style={topBanner ? { margin: 0 } : {}}>
            {data.optionalHeading && (
                <OptionalHeading hideOnMobile={hideHeadingOnMobile}>{data.optionalHeading}</OptionalHeading>
            )}
            <Columns asList={renderAsList} layoutOverride={layoutOverride}>
                {data.children.map((child: any, index: number) => {
                    const componentHandle = processChildComponentName(child)
                    const Component = componentMap[componentHandle] || React.Fragment

                    if (topBanner) {
                        return <Banner key={index} data={child} asTopBanner={true} />
                    }

                    if (componentHandle === 'interactiveForm') {
                        return (
                            <InteractiveForm
                                key={index}
                                data={child}
                                formData={formData.find(
                                    (form: any) => form.guid.toLowerCase() === child.interactiveFormId.toLowerCase()
                                )}
                            />
                        )
                    }

                    if (componentHandle === 'bijtellingCalculator') {
                        return (
                            <BijtellingCalculator key={index} data={child} configurationModels={configurationModels} />
                        )
                    }

                    if (componentHandle === 'carEditionSpecifications') {
                        return (
                            <CarEditionSpecifications
                                key={index}
                                data={child}
                                carEditionSpecificationLabels={
                                    carEditionSpecificationLabels as unknown as CarEditionSpecificationLabels
                                }
                            />
                        )
                    }
                    if (componentHandle === 'leaseComparisonTable') {
                        return <LeaseComparisonTable key={index} />
                    }

                    if (componentHandle === 'image') {
                        let imageVersion

                        if (data.children.length === 1 && data.background.startsWith('full')) {
                            imageVersion = ImageVersion.FullWidth
                        } else if (data.children.length === 1) {
                            imageVersion = ImageVersion.Large
                        } else if (data.children.length === 2) {
                            imageVersion = ImageVersion.Medium
                        } else {
                            imageVersion = ImageVersion.Small
                        }

                        return (
                            <Image
                                data={child}
                                version={imageVersion}
                                key={index}
                                shrinkImageIfNecessary={data.children.length === 1}
                            />
                        )
                    }

                    if (componentHandle === 'blogCategoryFeed') {
                        return <BlogCategoryFeed key={index} blogFeed={blogFeed} data={child} />
                    }

                    if (componentHandle === 'latestBlogPosts') {
                        return child.amountOfBlogPosts === 'all' ? (
                            <BlogFeed blogFeed={blogFeed.slice(0, 22)} key={index} />
                        ) : (
                            blogFeed
                                .filter((post: BlogPostData) => post.pageId !== pageId)
                                .slice(0, child.amountOfBlogPosts)
                                .map((post: BlogPostData, index: number) => (
                                    <LinkCard
                                        data={{ linkCard: [post] }}
                                        key={index}
                                        minimalOnMobile={minimalOnMobile}
                                        componentsInSection={
                                            child.amountOfBlogPosts > blogFeed.length
                                                ? child.amountOfBlogPosts
                                                : blogFeed.length
                                        }
                                    />
                                ))
                        )
                    }

                    if (componentHandle === 'form') {
                        return <Form data={child} parentSectionIndex={sectionIndex} key={index} />
                    }

                    if (componentHandle === 'trustpilotCarousel') {
                        return <TrustpilotCarousel trustpilotGlobals={trustpilotGlobals} key={index} />
                    }

                    return (
                        <Component
                            key={index}
                            data={child}
                            asListItem={renderAsList}
                            minimalOnMobile={minimalOnMobile}
                            componentsInSection={data.children.length}
                            layoutOverride={layoutOverride}
                        />
                    )
                })}
            </Columns>
        </Container>
    )
}

const OptionalHeading = styled.h2<{ hideOnMobile: boolean }>`
    max-width: 54rem;
    font-weight: var(--font-weight-medium);
    text-align: center;
    padding: 0 var(--gutter-width);
    margin: 0 auto var(--spacing-elements-more);

    ${props =>
        props.hideOnMobile &&
        css`
            @media (max-width: 47.5rem) {
                display: none;
            }
        `}
`

export default Section

export const query = graphql`
    fragment SectionFragment on Craft_components_component_BlockType {
        optionalHeading
        background
        children {
            remoteTypeName
            ... on Craft_components_banner_BlockType {
                ...BannerFragment
            }
            ... on Craft_components_bijtellingCalculator_BlockType {
                ...BijtellingCalculatorFragment
            }
            ... on Craft_components_blogCategoryFeed_BlockType {
                ...BlogCategoryFeedFragment
            }
            ... on Craft_components_callToAction_BlockType {
                ...CallToActionFragment
            }
            ... on Craft_components_callToActionHero_BlockType {
                ...CallToActionHeroFragment
            }
            ... on Craft_components_carEditionSpecifications_BlockType {
                ...CarEditionSpecificationsFragment
            }
            ... on Craft_components_descriptionList_BlockType {
                ...DescriptionListFragment
            }
            ... on Craft_components_faqCollection_BlockType {
                ...FAQCollectionFragment
            }
            ... on Craft_components_form_BlockType {
                introductoryText
                formId
            }
            ... on Craft_components_iframe_BlockType {
                iframe
            }
            ... on Craft_components_image_BlockType {
                ...ImageFragment
            }
            ... on Craft_components_interactiveForm_BlockType {
                ...InteractiveFormFragment
            }
            ... on Craft_components_latestBlogPosts_BlockType {
                amountOfBlogPosts
            }
            ... on Craft_components_linkBanner_BlockType {
                ...LinkBannerFragment
            }
            ... on Craft_components_linkCard_BlockType {
                ...LinkCardFragment
            }
            ... on Craft_components_list_BlockType {
                ...ListFragment
            }
            ... on Craft_components_referralCode_BlockType {
                ...ReferralCodeFragment
            }
            ... on Craft_components_text_BlockType {
                ...TextFragment
            }
            ... on Craft_components_textOnImage_BlockType {
                ...TextOnImageFragment
            }
            ... on Craft_components_trustpilotCarousel_BlockType {
                ...TrustpilotCarouselFragment
            }
        }
    }

    fragment ReusableComponentFragment on Craft_reusableComponents_reusableComponents_Entry {
        typeHandle
        componentType {
            ... on Craft_componentType_component_BlockType {
                optionalHeading
                background
                children {
                    remoteTypeName
                    ... on Craft_componentType_banner_BlockType {
                        ...BannerReusableComponentFragment
                    }
                    ... on Craft_componentType_bijtellingCalculator_BlockType {
                        ...BijtellingCalculatorReusableComponentFragment
                    }
                    ... on Craft_componentType_blogCategoryFeed_BlockType {
                        ...BlogCategoryFeedReusableComponentFragment
                    }
                    ... on Craft_componentType_callToAction_BlockType {
                        ...CallToActionReusableComponentFragment
                    }
                    ... on Craft_componentType_callToActionHero_BlockType {
                        ...CallToActionHeroReusableComponentFragment
                    }
                    ... on Craft_componentType_carEditionSpecifications_BlockType {
                        ...CarEditionSpecificationsReusableComponentFragment
                    }
                    ... on Craft_componentType_descriptionList_BlockType {
                        ...DescriptionListReusableComponentFragment
                    }
                    ... on Craft_componentType_faqCollection_BlockType {
                        ...FAQCollectionReusableComponentFragment
                    }
                    ... on Craft_componentType_form_BlockType {
                        introductoryText
                        formId
                    }
                    ... on Craft_componentType_iframe_BlockType {
                        iframe
                    }
                    ... on Craft_componentType_image_BlockType {
                        ...ImageReusableComponentFragment
                    }
                    ... on Craft_componentType_interactiveForm_BlockType {
                        ...InteractiveFormReusableComponentFragment
                    }
                    ... on Craft_componentType_latestBlogPosts_BlockType {
                        amountOfBlogPosts
                    }
                    ... on Craft_componentType_linkBanner_BlockType {
                        ...LinkBannerReusableComponentFragment
                    }
                    ... on Craft_componentType_linkCard_BlockType {
                        ...LinkCardReusableComponentFragment
                    }
                    ... on Craft_componentType_list_BlockType {
                        ...ListReusableComponentFragment
                    }
                    ... on Craft_componentType_referralCode_BlockType {
                        ...ReferralCodeReusableComponentFragment
                    }
                    ... on Craft_componentType_text_BlockType {
                        ...TextReusableComponentFragment
                    }
                    ... on Craft_componentType_textOnImage_BlockType {
                        ...TextOnImageReusableComponentFragment
                    }
                    ... on Craft_componentType_trustpilotCarousel_BlockType {
                        ...TrustpilotCarouselReusableComponentFragment
                    }
                }
            }
        }
    }
`
