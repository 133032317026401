import FlexibleLink, { LinkData } from '../core/link'
import Image, { ImageData, ImageVersion } from './image'
import { graphql, Link } from 'gatsby'
import { primaryButton } from './_shared-styles'
import React from 'react'
import styled, { css } from 'styled-components'
import { typographyMaxWidthBreakpoint } from '../core/global-styles'

interface LinkWrapperProps {
    children: JSX.Element | JSX.Element[]
    showButtonInsteadOfLink: boolean
}

const LinkWrapper = ({ children, showButtonInsteadOfLink }: LinkWrapperProps) =>
    showButtonInsteadOfLink ? <ButtonContainer>{children}</ButtonContainer> : <>{children}</>

export interface LinkCardData {
    image: ImageData[]
    alt?: string
    heading: string
    text?: string
    linkArray?: LinkData[]
    link?: {
        slug: string
        text: string
    }
    showButtonInsteadOfLink?: boolean
    growIfPossible?: boolean
}

export interface LinkCardProps {
    data: {
        linkCard: LinkCardData[]
    }
    asListItem?: boolean
    minimalOnMobile: boolean
    componentsInSection: number
    asFeatured?: boolean
    withoutText?: boolean
}

const LinkCard = ({
    data,
    asListItem,
    minimalOnMobile,
    componentsInSection,
    asFeatured = false,
    withoutText = false,
}: LinkCardProps): JSX.Element => {
    const card = data.linkCard[0]

    if (!card) {
        return <></>
    }

    const isWholeCardClickable = card?.text?.match(/<\/a>/)

    if (asFeatured) {
        return (
            <FeaturedItem
                isWholeCardClickable={isWholeCardClickable}
                withLongHeading={card.heading.split(/\s/g).length > 8}
            >
                {!!card.image.length && (
                    <Image data={{ image: card.image, alt: card.alt }} version={ImageVersion.Medium} />
                )}
                <TextContainer>
                    <Heading>{card.heading}</Heading>
                    {!withoutText && <p>{card.text}</p>}
                    {card.linkArray && card.linkArray[0] ? (
                        <FlexibleLink data={card.linkArray[0]} />
                    ) : (
                        card.link && <Link to={card.link.slug}>{card.link.text}</Link>
                    )}
                </TextContainer>
            </FeaturedItem>
        )
    }

    let imageVersion

    if (componentsInSection === 1) {
        imageVersion = ImageVersion.Large
    } else if (componentsInSection === 2 || card.growIfPossible) {
        imageVersion = ImageVersion.Medium
    } else {
        imageVersion = ImageVersion.Small
    }

    // Text could be a plain string, or rich text. Process it to be either a string of HTML or undefined (if it was already undefined).
    const text = !card.text || card.text.startsWith('<') ? card.text : `<p>${card.text}</p>`

    return (
        <Container
            as={asListItem ? 'li' : 'div'}
            minimalOnMobile={minimalOnMobile}
            componentsInSection={componentsInSection}
            growIfPossible={card.growIfPossible || false}
            isWholeCardClickable={isWholeCardClickable}
        >
            {!!card.image.length && <Image data={{ image: card.image, alt: card.alt }} version={imageVersion} />}
            <TextContainer>
                <Heading>{card.heading}</Heading>
                {!withoutText && !!text && <TextBox dangerouslySetInnerHTML={{ __html: text }} />}
            </TextContainer>
            {card.linkArray && card.linkArray[0] ? (
                <LinkWrapper showButtonInsteadOfLink={!!card.showButtonInsteadOfLink}>
                    <FlexibleLink data={card.linkArray[0]} />
                </LinkWrapper>
            ) : (
                card.link && <Link to={card.link.slug}>{card.link.text}</Link>
            )}
        </Container>
    )
}

export default LinkCard

const ButtonContainer = styled.div`
    ${primaryButton}
    width: initial;

    a {
        position: static;
        padding: 0.875rem 1.875rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &::after {
            position: relative;
            top: unset;
            right: unset;
            margin-left: 1rem;
        }
    }
`

const sharedStyles = css`
    background-color: var(--color-white);
    border-radius: 0.125rem;
    position: relative;
    color: var(--color-black);
    box-shadow: var(--box-shadow-less);
    display: flex;
    flex-direction: column;
    transition: transform 0.15s ease-in-out;

    > img,
    > picture,
    > picture > img {
        min-height: 11.5rem;
        height: calc(45vw * 0.66);
        max-height: 16rem;
        width: 100%;
        object-fit: cover;
    }

    > :last-child {
        margin: auto 1.5rem 1.875rem;

        &${ButtonContainer} {
            margin-bottom: 1.25rem;

            a {
                text-decoration: none;
            }
        }
    }

    & > :not(:last-child) a {
        color: var(--color-green-dark);
        text-decoration: none;
    }

    & > a:last-child {
        color: var(--color-green-dark);
        font-size: var(--font-size-medium);
        font-weight: var(--font-weight-medium);
        text-decoration: none;
        display: flex;

        &::after {
            content: '';
            height: 1rem;
            width: 1.5rem;
            flex-shrink: 0;
            align-self: end;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 96 96'%3E%3Cg%3E%3Cpath fill='%2340a58c' d='M12,52h62.344L53.172,73.172c-1.562,1.562-1.562,4.094,0,5.656c1.562,1.562,4.095,1.562,5.657,0l28-28 c1.562-1.562,1.562-4.095,0-5.656l-28-28C58.048,16.391,57.024,16,56,16c-1.023,0-2.047,0.391-2.828,1.172 c-1.562,1.562-1.562,4.095,0,5.656L74.344,44H12c-2.209,0-4,1.791-4,4S9.791,52,12,52z'/%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            margin: 0.5rem 0 0.5rem 0.375rem;

            @media (max-width: ${typographyMaxWidthBreakpoint}) {
                margin: 0.375rem 0 0.375rem 0.375rem;
            }
        }
    }

    &:hover {
        transform: translateY(-0.125rem);
    }
`

const sharedClickableCardStyles = css`
    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
`

interface ContainerInterface {
    componentsInSection: number
    minimalOnMobile: boolean
    growIfPossible: boolean
    isWholeCardClickable: boolean
}

const Container = styled.div<ContainerInterface>`
    ${sharedStyles}

    ${props =>
        props.minimalOnMobile &&
        css`
            @media (max-width: 47.5rem) {
                > img,
                > picture,
                > picture > img {
                    display: none;
                }
            }
        `}

    ${props =>
        props.componentsInSection >= 3 &&
        css`
            > img,
            > picture,
            > picture > img {
                height: 11.5rem;
            }
        `}

  ${props =>
        props.componentsInSection > 3 &&
        css`
            h3 {
                font-weight: var(--font-weight-medium);
            }

            @media (min-width: 63rem) {
                > img,
                > picture,
                > picture > img {
                    height: 14.5rem;
                }
            }
        `}

  ${props =>
        props.isWholeCardClickable &&
        css`
            > a:last-child,
            > :last-child a {
                ${sharedClickableCardStyles}
            }
        `}
`

const TextContainer = styled.div`
    color: var(--color-black);
    margin: 1.5rem 1.5rem 0;
`

const Heading = styled.h3`
    font-weight: var(--font-weight-bold);
    margin: 0 0 var(--spacing-elements-less);
`

const TextBox = styled.div`
    margin: 0 0 1.5rem;

    p,
    li,
    blockquote {
        color: var(--color-grey-dark);
    }
`

const FeaturedItem = styled.div<{ isWholeCardClickable: boolean; withLongHeading: boolean }>`
    ${sharedStyles}

    > ${TextContainer} {
        margin: 1.5rem 1.5rem 1.875rem;
    }

    p {
        margin: 0 0 1.5rem;
    }

    a {
        margin: 0 0 2rem;
    }

    @media (min-width: 62.5rem) {
        flex-direction: row;
        padding: 0 1.5rem 0 0;

        > img,
        > picture,
        > picture > img {
            height: 21rem;
            max-height: 21rem;
            margin: 0;
        }

        > picture {
            flex-shrink: 0;
            width: 60%;
        }

        > ${TextContainer} {
            width: 40%;
            margin: auto 1.5rem;

            > h2,
            > h3 {
                font-size: var(--font-size-extra-large);
            }
        }

        a {
            margin: 0;
        }
    }

    ${props =>
        props.withLongHeading &&
        css`
            @media (min-width: 62.5rem) {
                > picture {
                    width: 55%;
                }

                > ${TextContainer} {
                    width: 45%;

                    > h2,
                    > h3 {
                        font-size: var(--font-size-large);
                    }
                }
            }
        `}

    ${props =>
        props.isWholeCardClickable &&
        css`
            a {
                ${sharedClickableCardStyles}
            }
        `}
`

export const query = graphql`
    fragment LinkCardFragment on Craft_components_linkCard_BlockType {
        linkCard {
            ... on Craft_linkCard_BlockType {
                ...LinkCardSharedFieldsFragment
            }
        }
    }

    fragment LinkCardReusableComponentFragment on Craft_componentType_linkCard_BlockType {
        linkCard {
            ... on Craft_linkCard_BlockType {
                ...LinkCardSharedFieldsFragment
            }
        }
    }

    fragment LinkCardSharedFieldsFragment on Craft_linkCard_BlockType {
        image {
            filename
            dateUpdated
            height
            width
        }
        alt
        heading
        text
        linkArray {
            ... on Craft_linkArray_internalLink_BlockType {
                text
                entry {
                    typeHandle
                    slug
                }
            }
            ... on Craft_linkArray_externalLink_BlockType {
                text
                linkUrl
            }
        }
        showButtonInsteadOfLink
        growIfPossible
    }
`
