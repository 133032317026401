import { CraftEntry } from '../../utilities/general/types'
import { getLocaleSlug } from '../../utilities/general/locale'
import Image, { ImageData, ImageVersion } from '../craft-cms/image'
import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import WaveBackground from '../background/wave-background'

interface MinimalBlogPostData {
    currentLanguage: string
    entry: CraftEntry[]
    heading: string
    image: ImageData[]
}

const MinimalBlogPost = ({ currentLanguage, entry, heading, image }: MinimalBlogPostData): JSX.Element => (
    <BlogLink to={getLocaleSlug(entry[0], currentLanguage)}>
        <Image data={{ image: image }} version={ImageVersion.Small} />
        <Text>{heading}</Text>
    </BlogLink>
)

export default MinimalBlogPost

const BlogLink = styled(Link)`
    background: var(--gradient-green);
    color: var(--color-white);
    display: flex;
    align-items: stretch;
    border-radius: 3px;
    overflow: hidden;
    transition: transform 0.2s ease;
    transform: scale(1);

    &:hover {
        transform: scale(1.01);
    }

    ${WaveBackground} & {
        background: var(--color-white);
        color: var(--color-black);
    }

    picture {
        width: 10rem;
        min-height: 6rem;
        margin: 0;
        position: relative;
    }

    img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`

const Text = styled.h3`
    font-size: var(--font-size-medium);
    margin: 0;
    padding: 0.5rem 2.25rem 0.5rem 1rem;
    flex: 1;
    display: flex;
    position: relative;
    align-items: center;

    &::after {
        content: '';
        position: absolute;
        width: 1.25rem;
        height: 1.25rem;
        bottom: 0.75rem;
        right: 1rem;
        background-repeat: no-repeat;
        background-size: 1.25rem 1.25rem;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 96 96'%3E%3Cg%3E%3Cpath fill='white' d='M12,52h62.344L53.172,73.172c-1.562,1.562-1.562,4.094,0,5.656c1.562,1.562,4.095,1.562,5.657,0l28-28 c1.562-1.562,1.562-4.095,0-5.656l-28-28C58.048,16.391,57.024,16,56,16c-1.023,0-2.047,0.391-2.828,1.172 c-1.562,1.562-1.562,4.095,0,5.656L74.344,44H12c-2.209,0-4,1.791-4,4S9.791,52,12,52z'/%3E%3C/g%3E%3C/svg%3E");

        ${WaveBackground} & {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 96 96'%3E%3Cg%3E%3Cpath fill='black' d='M12,52h62.344L53.172,73.172c-1.562,1.562-1.562,4.094,0,5.656c1.562,1.562,4.095,1.562,5.657,0l28-28 c1.562-1.562,1.562-4.095,0-5.656l-28-28C58.048,16.391,57.024,16,56,16c-1.023,0-2.047,0.391-2.828,1.172 c-1.562,1.562-1.562,4.095,0,5.656L74.344,44H12c-2.209,0-4,1.791-4,4S9.791,52,12,52z'/%3E%3C/g%3E%3C/svg%3E");
        }
        transition: transform 250ms ease;

        a:hover & {
            transform: translateX(0.25rem);
        }
    }
`
