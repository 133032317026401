import React from 'react'
import Table from '../../utilities/table/Table'

export type LeaseComparisonTableData = { label: string; values: string[]; description?: string[] }

export type LeaseComparisonTableProps = {
    leaseTypes: string[]
    data: LeaseComparisonTableData[]
}

const LeaseComparisonTable = (): JSX.Element => {
    const values = tableValues as LeaseComparisonTableProps
    const headers = values.leaseTypes

    return <Table headers={headers} values={values.data} />
}
export default LeaseComparisonTable

const tableValues = {
    leaseTypes: ['Full operational Lease', 'Private Lease', 'Ready2Share', 'Short lease', 'Financial lease'],
    data: [
        {
            label: 'Duur contract',
            values: ['> 12 maanden', '> 12 maanden', '> 12 maanden', 'Maandelijks opzegbaar', 'Tot aflossing lening'],
        },
        { label: 'Vast maandbedrag', values: ['Ja', 'Ja', 'Ja', 'Ja', 'Nee'] },
        { label: 'Auto vermeld op de balans van de klant', values: ['Nee', 'Nee', 'Nee', 'Nee', 'Ja'] },
        { label: 'Economisch eigenaar', values: ['Nee', 'Nee', 'Nee', 'Nee', 'Ja'] },
        { label: 'Juridisch Eigenaar', values: ['Nee', 'Nee', 'Nee', 'Nee', 'Na het contract'] },
        { label: 'Zakelijke leasevorm?', values: ['Ja', 'Nee', 'Ja', 'Nee', 'Ja'] },
        { label: 'BKR Registratie?', values: ['Nee', 'Ja', 'Nee', 'Nee', 'Nee'] },
        {
            label: 'Services inbegrepen',
            description: [
                '✓ Verzekering',
                '✓ Wegenbelasting (vanaf 2025)',
                '✓ Hulp bij pech',
                '✓ Onderhoud en reparaties',
                '✓ Bandenvervanging',
                '✓ Vervangend vervoer',
            ],
            values: ['Ja', 'Ja', 'Ja', 'Ja', 'Nee'],
        },
        { label: 'Laadkosten inbegrepen', values: ['Nee', 'Nee', 'Ja', 'Nee', 'Nee'] },
        { label: 'Bijtelling', values: ['Ja > 500km prive', 'Nee', 'Nee', 'Ja / Nee (Zakelijk/Prive)', 'Ja'] },
    ],
}
