import { CarData } from '../../utilities/car-edition-specification/data'
import {
    CarEditionSpecificationLabels,
    getHeadingLabels,
    CarEditionData,
} from '../../utilities/car-edition-specification/labels'
import CarSpecificationColumns from './car-specification-columns'
import React from 'react'

interface CarSpecificationRowProps {
    carEditionSpecificationLabels: CarEditionSpecificationLabels
    keyToRender: keyof CarEditionData
    model: CarData
}

const CarSpecificationRow = ({
    carEditionSpecificationLabels,
    keyToRender,
    model,
}: CarSpecificationRowProps): JSX.Element => {
    const { label, description } = getHeadingLabels(carEditionSpecificationLabels, keyToRender)
    let rowHeading = <th>{label}</th>

    if (description) {
        rowHeading = (
            <th>
                <details>
                    <summary>{label}</summary>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                </details>
            </th>
        )
    }

    return (
        <tr>
            {rowHeading}
            <CarSpecificationColumns
                carEditionSpecificationLabels={carEditionSpecificationLabels}
                keyToRender={keyToRender}
                model={model}
            />
        </tr>
    )
}

export default CarSpecificationRow
