import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import React from 'react'
import styled from 'styled-components'

interface MaterialRadioButton {
    value: string
    label: string
}

interface MaterialRadioButtonsProps {
    defaultValue: string
    label: string
    onChange(arg: string): void
    selectables: MaterialRadioButton[]
}

const MaterialRadioButtons = ({
    defaultValue,
    label,
    onChange,
    selectables,
}: MaterialRadioButtonsProps): JSX.Element => {
    const radioGroupName = label.replace(/\s/g, '_')

    return (
        <Container>
            <FormControl>
                <FormLabel>{label}</FormLabel>
                <RadioGroup
                    row
                    aria-label={label}
                    name={radioGroupName}
                    defaultValue={defaultValue}
                    onChange={event => onChange(event.target.value)}
                >
                    {selectables.map(({ value, label }) => (
                        <FormControlLabel key={value} value={value} control={<Radio color="primary" />} label={label} />
                    ))}
                </RadioGroup>
            </FormControl>
        </Container>
    )
}

export default MaterialRadioButtons

const Container = styled.div`
    .MuiFormLabel-root {
        font-weight: var(--font-weight-bold);
    }
`
