import styled from 'styled-components'

const FullWidthBackground = styled.div`
    * + & {
        margin: var(--vertical-spacing) 0 0;
    }

    [class*='full-'] + &:nth-child(2) {
        margin: 0;
    }

    > :first-child {
        max-width: 100%;
        padding: 0;
        margin: 0;
    }

    /* 
    The combination fullWidth with only a textOnImage
    component is often used for hero images, which without
    this nudge turn out to be not nearly tall enough.
  */
    [class^='text-on-image__OuterContainer']:only-child {
        @media (min-height: 27.5rem) {
            min-height: 20rem;
        }
    }
`

export default FullWidthBackground
