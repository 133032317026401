import { CarData } from '../../utilities/car-edition-specification/data'
import { CarEditionSpecificationLabels } from '../../utilities/car-edition-specification/labels'
import { LocaleObject } from '../../utilities/general/types'
import React from 'react'
import CarSpecificationColumns from './car-specification-columns'
import CarSpecificationBijtellingRow from './car-specification-bijtelling-row'
import CarSpecificationRow from './car-specification-row'

interface CarSpecificationInnerTableProps {
    carEditionSpecificationLabels: CarEditionSpecificationLabels
    currentLocale: LocaleObject
    model: CarData
}

export const CarSpecificationInnerTable = ({
    carEditionSpecificationLabels,
    currentLocale,
    model,
}: CarSpecificationInnerTableProps): JSX.Element => {
    const { editions } = model

    return (
        <>
            <thead>
                <tr>
                    <th>
                        {model.brandName} {model.modelName}
                    </th>
                    <CarSpecificationColumns
                        carEditionSpecificationLabels={carEditionSpecificationLabels}
                        keyToRender="editionName"
                        model={model}
                    />
                </tr>
            </thead>
            <tbody>
                <CarSpecificationRow
                    carEditionSpecificationLabels={carEditionSpecificationLabels}
                    keyToRender="fiscalValue"
                    model={model}
                />
                <CarSpecificationRow
                    carEditionSpecificationLabels={carEditionSpecificationLabels}
                    keyToRender="leasePriceFolMinimum"
                    model={model}
                />
                <CarSpecificationRow
                    carEditionSpecificationLabels={carEditionSpecificationLabels}
                    keyToRender="rangeWltpInKm"
                    model={model}
                />
                <CarSpecificationRow
                    carEditionSpecificationLabels={carEditionSpecificationLabels}
                    keyToRender="rangeRealisticInKm"
                    model={model}
                />
                <CarSpecificationRow
                    carEditionSpecificationLabels={carEditionSpecificationLabels}
                    keyToRender="accelerationFrom0To100KmInSeconds"
                    model={model}
                />
                <CarSpecificationRow
                    carEditionSpecificationLabels={carEditionSpecificationLabels}
                    keyToRender="topSpeedInKm"
                    model={model}
                />
                <CarSpecificationRow
                    carEditionSpecificationLabels={carEditionSpecificationLabels}
                    keyToRender="seatCount"
                    model={model}
                />
                <CarSpecificationRow
                    carEditionSpecificationLabels={carEditionSpecificationLabels}
                    keyToRender="expectedDeliveryTime"
                    model={model}
                />
                <CarSpecificationRow
                    carEditionSpecificationLabels={carEditionSpecificationLabels}
                    keyToRender="motorDescription"
                    model={model}
                />
                <CarSpecificationRow
                    carEditionSpecificationLabels={carEditionSpecificationLabels}
                    keyToRender="recommendation"
                    model={model}
                />
                {editions.some(edition => edition.editionInformationLink[0].linkArray.length) && (
                    <CarSpecificationRow
                        carEditionSpecificationLabels={carEditionSpecificationLabels}
                        keyToRender="editionInformationLink"
                        model={model}
                    />
                )}
            </tbody>
        </>
    )
}
