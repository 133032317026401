import React, { Component, useEffect } from 'react'
import { graphql } from 'gatsby'
import { loadScript } from '../../utilities/general/loadScript'
// eslint-disable-next-line react/prop-types
const TrustBox = ({ trustBoxRef, countryId, countryLink }) => {
    useEffect(() => {
        loadScript('https:////widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js')
    })

    return (
        <div
            ref={trustBoxRef} // We need a reference to this element to load the TrustBox in componentDidMount.
            className="trustpilot-widget" // Renamed this to className.
            // [ long list of data attributes...]
            data-locale="us-US"
            data-template-id="53aa8912dec7e10d38f59f36"
            data-businessunit-id={countryId}
            data-style-height="140px"
            data-style-width="100%"
            data-theme="light"
            data-stars="4,5"
            data-font-family="Montserrat"
            data-text-color="#222222"
        >
            <a href={countryLink} target="_blank" rel="noopener noreferrer">
                Trustpilot
            </a>
        </div>
    )
}
class TrustpilotCarousel extends Component {
    private trustBoxRef: React.RefObject<unknown>
    private trustpilotGlobals: any
    constructor(props) {
        super(props)
        this.trustBoxRef = React.createRef()
        // eslint-disable-next-line react/prop-types
        this.trustpilotGlobals = props.trustpilotGlobals
    }
    componentDidMount() {
        // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
        // If it's not, it means the script you pasted into <head /> isn't loaded just yet.
        // When it is, it will automatically load the TrustBox.
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(this.trustBoxRef.current, true)
        }
    }
    render() {
        return (
            <TrustBox
                trustBoxRef={this.trustBoxRef}
                countryId={this.trustpilotGlobals.countryId}
                countryLink={this.trustpilotGlobals.countryLink}
            />
        )
    }
}
export default TrustpilotCarousel

export const query = graphql`
    fragment TrustpilotCarouselFragment on Craft_components_trustpilotCarousel_BlockType {
        id
    }

    fragment TrustpilotCarouselReusableComponentFragment on Craft_componentType_trustpilotCarousel_BlockType {
        id
    }
`
