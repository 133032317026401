import addPrefetchLink from '../../utilities/general/add-prefetch-link'
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

function getIFrameVersion(src: string): string {
    if (src.indexOf('youtube.com/embed') > -1) {
        return 'youtube'
    }

    if (src.indexOf('api.soundcloud.com') > -1) {
        return 'soundcloud'
    }

    if (src.indexOf('locations.ready2share.net') > -1) {
        return 'ready2ShareLocations'
    }

    return 'responsive'
}

interface FrameData {
    iframe: string
}

interface FrameProps {
    data: FrameData
}

const IFrame = ({ data }: FrameProps): JSX.Element => {
    const version = getIFrameVersion(data.iframe)
    const [youtubeIFrameActivated, setYoutubeIFrameActivated] = useState(false)

    useEffect(() => {
        if (version === 'youtube') {
            addPrefetchLink('preconnect', 'https://www.youtube.com')
        }
    }, [])

    if (version === 'youtube') {
        const videoIdSliceStartIndex = data.iframe.indexOf('embed/') + 6
        const videoIdSliceEndIndex = data.iframe.indexOf('?') > -1 ? data.iframe.indexOf('?') : data.iframe.length
        const videoId = data.iframe.slice(videoIdSliceStartIndex, videoIdSliceEndIndex)

        const onYoutubeContainerClick = (event: MouseEvent) => {
            event.preventDefault()
            setYoutubeIFrameActivated(true)
        }

        return (
            <Container version="youtube" aria-hidden="true">
                <a
                    href={`https://www.youtube.com/watch?v=${videoId}`}
                    onClick={(event: any) => onYoutubeContainerClick(event)}
                >
                    <img src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`} alt="" loading="lazy" />
                    <button aria-label="Play" />
                </a>
                {youtubeIFrameActivated && (
                    <iframe
                        height="180"
                        width="320"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                        title={'Intentionally blank'}
                        loading="lazy"
                        frameBorder="0"
                    />
                )}
            </Container>
        )
    }

    return (
        <Container version={version}>
            <iframe
                src={data.iframe}
                height="180"
                width="320"
                title={'Intentionally blank'}
                aria-hidden="true"
                loading="lazy"
            />
        </Container>
    )
}

const Container = styled.div<{ version: string }>`
    position: relative;
    overflow: hidden;
    align-self: flex-start;

    iframe {
        width: 100%;
        border: 0;
    }

    ${props =>
        props.version === 'youtube' &&
        css`
            padding-top: 56.25%;

            img {
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                object-fit: cover;
                object-position: center center;
            }

            a {
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;

                &:hover {
                    opacity: 1;
                }
            }

            button {
                height: 48px;
                width: 68px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                filter: grayscale(100%);
                background-color: transparent;
                background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 68 48"><path fill="%23f00" fill-opacity="0.8" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"></path><path d="M 45,24 27,14 27,34" fill="%23fff"></path></svg>');
                border: none;
                cursor: pointer;
                transition: filter 0.1s cubic-bezier(0, 0, 0.2, 1);

                &:focus,
                &:hover {
                    filter: none;
                    outline: none;
                }
            }

            iframe {
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
        `}

    ${props =>
        props.version === 'youtube' &&
        css`
            padding-top: 56.25%;

            iframe {
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
        `}

  ${props =>
        props.version === 'ready2ShareLocations' &&
        css`
            iframe {
                height: calc(100vh - 2rem);
                max-height: 37.5rem;
            }

            @media (min-width: 60rem) {
                padding-top: 56.25%;

                iframe {
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        `}
`

export default IFrame
