import React from 'react'
import { TableProps } from './Table'
import TableRow from './TableRow'

const InnerTable = ({ headers, values }: TableProps): JSX.Element => {
    return (
        <>
            <thead>
                <tr>
                    <th></th>
                    {headers.map((header, index) => (
                        <th key={`${header}-${index}`}>
                            <div>{header}</div>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {values.map((value, index) => (
                    <TableRow key={index} row={value} />
                ))}
            </tbody>
        </>
    )
}
export default InnerTable
