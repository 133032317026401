import { graphql } from 'gatsby'
import { listStyles, listItemStyles } from './_shared-styles'
import React from 'react'
import styled from 'styled-components'

export interface ListData {
    text: string
}

export interface ListProps {
    data: {
        items: ListData[]
    }
}

const List = ({ data }: ListProps): JSX.Element => (
    <ListContainer>
        {data.items.map((item: ListData, index: number) => (
            <ListItem key={index}>{item.text}</ListItem>
        ))}
    </ListContainer>
)

export default List

const ListContainer = styled.ul`
    ${listStyles}
`

const ListItem = styled.li`
    ${listItemStyles}
`

export const query = graphql`
    fragment ListFragment on Craft_components_list_BlockType {
        isOL
        items {
            text
        }
    }

    fragment ListReusableComponentFragment on Craft_componentType_list_BlockType {
        isOL
        items {
            text
        }
    }
`
