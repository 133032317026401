import { BlogPostData, getPostsInCategories } from '../../utilities/general/blog-feed'
import { CraftEntry } from '../../utilities/general/types'
import { graphql } from 'gatsby'
import { LinkData } from '../core/link'
import { Locale } from '../../utilities/general/locale'
import MinimalBlogPost from '../ui/minimal-blog-post'
import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'

interface BlogCategoryFeedData {
    amountOfPosts: string
    optionalFeedHeading?: string
    optionalFeedText?: string
    selectedCategory?: {
        title: string
    }[]
}

interface BlogCategoryFeedProps {
    blogFeed: BlogPostData[]
    data: BlogCategoryFeedData
}

const BlogCategoryFeed = ({ blogFeed, data }: BlogCategoryFeedProps): JSX.Element => {
    const { language } = useContext(Locale)
    const { amountOfPosts, optionalFeedHeading, optionalFeedText, selectedCategory } = data
    const includedPosts = useMemo(() => {
        let posts = getPostsInCategories({ allBlogPosts: blogFeed, selectedCategory })

        if (amountOfPosts !== 'all') {
            posts = posts.slice(0, parseInt(amountOfPosts))
        }

        return posts
    }, [amountOfPosts, blogFeed, selectedCategory])

    return (
        <Container>
            {!!optionalFeedHeading && <OptionalHeading>{optionalFeedHeading}</OptionalHeading>}
            {!!optionalFeedText && <OptionalText dangerouslySetInnerHTML={{ __html: optionalFeedText }} />}
            <PostList>
                {includedPosts.map((post, index) => (
                    <ListItem key={index}>
                        <MinimalBlogPost
                            currentLanguage={language}
                            entry={(post.linkArray as LinkData[])[0].entry as CraftEntry[]}
                            heading={post.heading}
                            image={post.image}
                        />
                    </ListItem>
                ))}
            </PostList>
        </Container>
    )
}

export default BlogCategoryFeed

const Container = styled.div``

const OptionalHeading = styled.h2`
    max-width: 60ch;
    margin: 0 0 var(--spacing-elements-less);
`

const OptionalText = styled.div`
    max-width: 60ch;
`

const ListItem = styled.li``

const PostList = styled.ol`
    list-style: none;
    padding: 0;

    /* 
    Holy Albatross: 
    - https://heydonworks.com/article/the-flexbox-holy-albatross-reincarnated/
    - https://css-tricks.com/holy-albatross-with-widths/
  */
    --breakpoint: 50rem;
    --modifier: calc((var(--breakpoint) - 100%) * 999);
    --gutter: 1.5rem;
    margin: calc(var(--gutter) / 2 * -1);
    display: flex;
    flex-wrap: wrap;

    ${ListItem} {
        --width: calc(50% - var(--gutter));
        flex-basis: max(var(--width, var(--auto-width)), var(--modifier));
        max-width: max(var(--width, var(--max-width)), var(--modifier));
        margin: calc(var(--gutter) / 2);
    }
`

export const query = graphql`
    fragment BlogCategoryFeedFragment on Craft_components_blogCategoryFeed_BlockType {
        amountOfPosts
        optionalFeedHeading
        optionalFeedText
        selectedCategory {
            title
        }
    }

    fragment BlogCategoryFeedReusableComponentFragment on Craft_componentType_blogCategoryFeed_BlockType {
        amountOfPosts
        optionalFeedHeading
        optionalFeedText
        selectedCategory {
            title
        }
    }
`
