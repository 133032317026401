import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

const Container = styled.div`
    width: 100%;
    margin: 0 auto;
`

const Title = styled.h2`
    max-width: 60ch;
`

const Introduction = styled.div`
    font-size: var(--font-size-medium);
    max-width: 60ch;
`

const FAQList = styled.div`
    max-width: 100%;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, min(30rem, 100%));
    grid-gap: 0 4rem;
`

const FAQListColumn = styled.div``

const FAQItem = styled.details`
    margin-bottom: 1.2rem;
`

const FAQTitle = styled.summary`
    display: inline;
    opacity: 0.87;
    color: inherit;
    font-family: var(--font-headings);
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-height-headings);
    cursor: pointer;
    padding-left: 1.8rem;
    position: relative;
    outline: none;

    ${FAQItem}[open] &,
  &:hover {
        opacity: 1;
    }

    &::-webkit-details-marker {
        display: none;
    }
`

const FAQIcon = styled.span`
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 1em;
    height: var(--line-height-headings);
    transform-origin: center;
    transform: scale(0.8);
    transition: transform 0.15s ease;

    ${FAQItem}[open] & {
        outline: none;
        transform: rotate(90deg) scale(0.9);
    }
`

const FAQAnswer = styled.div`
    padding-left: 1.8rem;
    margin-bottom: 2rem;
`

export interface FAQProperties {
    title: string
    answer: string
}

export interface FAQCollectionProperties {
    data: {
        optionalFaqHeading?: string
        optionalFaqIntroduction?: string
        displayedFaqs: FAQProperties[]
    }
    layoutOverride?: string
}

const FAQCollection = ({
    data: { optionalFaqHeading, optionalFaqIntroduction, displayedFaqs },
}: FAQCollectionProperties): JSX.Element => {
    const faqColumns: FAQProperties[][] = [displayedFaqs.splice(0, Math.ceil(displayedFaqs.length / 2)), displayedFaqs]

    return (
        <Container>
            {optionalFaqHeading ? <Title>{optionalFaqHeading}</Title> : ''}
            {optionalFaqIntroduction ? (
                <Introduction dangerouslySetInnerHTML={{ __html: optionalFaqIntroduction }} />
            ) : (
                ''
            )}
            <FAQList>
                {faqColumns.map((faqItems, columnIndex) => (
                    <FAQListColumn key={columnIndex}>
                        {faqItems.map((faqItem, index) => (
                            <FAQItem key={index}>
                                <FAQTitle>
                                    <FAQIcon>►</FAQIcon>
                                    {faqItem.title}
                                </FAQTitle>
                                <FAQAnswer dangerouslySetInnerHTML={{ __html: faqItem.answer }} />
                            </FAQItem>
                        ))}
                    </FAQListColumn>
                ))}
            </FAQList>
        </Container>
    )
}

export default FAQCollection

export const query = graphql`
    fragment FAQCollectionFragment on Craft_components_faqCollection_BlockType {
        optionalFaqHeading
        optionalFaqIntroduction
        displayedFaqs {
            ... on Craft_faqs_faqs_Entry {
                title
                answer
            }
        }
    }
    fragment FAQCollectionReusableComponentFragment on Craft_componentType_faqCollection_BlockType {
        optionalFaqHeading
        optionalFaqIntroduction
        displayedFaqs {
            ... on Craft_faqs_faqs_Entry {
                title
                answer
            }
        }
    }
`
