import { BijtellingResults, BijtellingYear, currentYear } from '@mistergreen/shared/utilities/bijtelling'
import { CarEditionData, CarEditionSpecificationLabels, getLabel, LabelType } from './labels'
import formatWithCurrencySymbol from '../../utilities/general/format-with-currency-symbol'
import { getLocaleString } from '../general/locale'
import { LinkData } from '../../components/core/link'
import { LocaleObject } from '../general/types'

export interface CarData {
    brandName: string
    modelName: string
    modelInformationLink: LinkData[]
    modelConfiguratorLink: LinkData[]
    editions: CarEditionData[]
}

interface WrapWithValueTemplateParam {
    columnData: string
    template: string
    varToReplace: string
}

export const wrapWithValueTemplate = ({ columnData, template, varToReplace }: WrapWithValueTemplateParam): string => {
    if (template && typeof columnData === 'string') {
        const replaceRegExp = new RegExp(`\\{\\{\\s*${varToReplace}\\s*\\}\\}`)
        return template.replace(replaceRegExp, columnData)
    }

    return columnData
}

interface PrepareEditionColumnDataParam {
    carEditionSpecificationLabels: CarEditionSpecificationLabels
    edition: CarEditionData
    keyToRender: keyof CarEditionData
    locale: LocaleObject
}

export const prepareEditionColumnData = ({
    carEditionSpecificationLabels,
    edition,
    keyToRender,
    locale,
}: PrepareEditionColumnDataParam): string => {
    let columnData = edition[keyToRender]

    if (['fiscalValue', 'leasePriceFolMinimum'].includes(keyToRender)) {
        columnData = formatWithCurrencySymbol(columnData as number, locale, { displayAsFloat: false })
    } else if (typeof columnData === 'number') {
        columnData = Intl.NumberFormat(getLocaleString(locale)).format(columnData)
    }

    const template = getLabel(carEditionSpecificationLabels, keyToRender, LabelType.Template)
    return wrapWithValueTemplate({ columnData: columnData as string, template, varToReplace: keyToRender })
}

interface PrepareBijtellingColumnDataParam {
    bijtellingResult: BijtellingResults
    bijtellingYear: BijtellingYear
    carEditionSpecificationLabels: CarEditionSpecificationLabels
}

export const prepareBijtellingColumnData = ({
    bijtellingResult,
    bijtellingYear,
    carEditionSpecificationLabels,
}: PrepareBijtellingColumnDataParam): string => {
    const bijtelling =
        bijtellingYear === 2021
            ? Math.round(bijtellingResult.bracketOneResultCurrentYear)
            : Math.round(bijtellingResult.bracketOneResultNextYear)

    const columnData = formatWithCurrencySymbol(bijtelling, 'nl-NL', { hardcodeCurrency: 'EUR' })

    const template =
        bijtellingYear === currentYear
            ? carEditionSpecificationLabels.bijtellingCurrentYearTemplate
            : carEditionSpecificationLabels.bijtellingNextYearTemplate

    const varToReplace = bijtellingYear === currentYear ? 'bijtellingCurrentYear' : 'bijtellingNextYear'

    return wrapWithValueTemplate({ columnData, template, varToReplace })
}

interface PrepareEditionLinkParam {
    edition: CarEditionData
    editionLinkTemplate: string
    model: CarData
}

export const prepareEditionLink = ({ edition, editionLinkTemplate, model }: PrepareEditionLinkParam): LinkData => {
    const linkData = {
        ...model.modelConfiguratorLink[0],
        text: editionLinkTemplate.replace(/\{\{\s*edition\s*\}\}/, edition.editionName),
    }

    let editionLinkData

    if (edition.editionQueryParamsString) {
        if (linkData.linkUrl) {
            const linkUrl = linkData.linkUrl + edition.editionQueryParamsString

            editionLinkData = { text: linkData.text, linkUrl }
        } else if (linkData?.entry?.length && linkData.entry[0]?.slug) {
            const slug = linkData.entry[0].slug + edition.editionQueryParamsString

            editionLinkData = {
                ...linkData,
                ...{ entry: [{ ...linkData.entry[0], slug }] },
            }
        }
    }

    return editionLinkData || linkData
}
