import { DataLayer } from '../../utilities/general/data-layer'
import { executeOnce } from '../../utilities/general/execute-once'
import FormLoadingPlaceholder from '../ui/form-loading-placeholder'
import { Locale } from '../../utilities/general/locale'
import { LocaleObject } from '../../utilities/general/types'
import React, { useContext, useEffect, RefObject, useState, useRef } from 'react'
import styled from 'styled-components'
import useLazyLoadedScript from '../../utilities/hooks/use-lazy-loaded-script'

declare global {
    interface Window {
        hubspot: any
        hbspt: any
    }
}

interface FormData {
    introductoryText: string
    formId: string
}

interface FormProps {
    data: FormData
    parentSectionIndex: number
}

const bindGlobalEventListeners = executeOnce(() => {
    // make sure this is executed in a browser
    if (typeof window === 'undefined') {
        return
    }

    window.addEventListener('message', event => {
        if (event.data && event.data.type === 'hsFormCallback') {
            const UnfilteredOptions = (event.data && event.data.data && event.data.data.length && event.data.data) || []

            if (UnfilteredOptions.length <= 0) {
                return
            }

            const Options =
                UnfilteredOptions.filter(
                    (field: any) => ['firstname', 'lastname', 'phone', 'email', 'company'].indexOf(field?.name) === -1
                )
                    .map((field: any) => field.name.toLowerCase() + '=' + field.value.toString().toLowerCase())
                    .join('|') || undefined

            const nameForm = event.data.id
            const formType = 'offer'

            switch (event.data.eventName) {
                case 'onFormReady':
                    DataLayer.push({
                        event: 'forms.show',
                        formType: formType,
                        nameForm: nameForm,
                        Options: Options,
                        Status: undefined,
                    })
                    break

                case 'onFormSubmit':
                    DataLayer.push({
                        event: 'forms.submitted',
                        formType: formType,
                        nameForm: nameForm,
                        Options: Options,
                        Status: undefined,
                        userId: undefined,
                        orderId: undefined,
                    })
                    break
            }
        }
    })
})

const Form = ({ data, parentSectionIndex }: FormProps): JSX.Element => {
    const src = '//js.hsforms.net/forms/v2.js'
    const { current: id } = useRef(`hubspot-form-${data.formId}-${parentSectionIndex}`)

    const [displayPlaceholder, setDisplayPlaceholder] = useState(true)

    const [ref, loaded, error] = useLazyLoadedScript(src, { rootMargin: '200px 0px' })

    const currentLocale: LocaleObject = useContext(Locale)

    bindGlobalEventListeners()

    function createForm() {
        // make sure this is executed in a browser
        if (typeof window === 'undefined') {
            return
        }

        if (!window.hbspt || !window.hbspt.forms) {
            return setTimeout(createForm, 1)
        }

        const options = {
            portalId: '3520736',
            formId: data.formId,
            locale: currentLocale.language,
            target: `#${id}`,
        }

        try {
            window.hubspot.utils.shouldRenderFormRawHtml = () => true
        } catch {
            console.warn('Form renders with default styling.')
        }

        window.hbspt.forms.create(options)
    }

    useEffect(() => {
        if (loaded && !error) {
            createForm()
        }
    }, [loaded])

    const hideLoadingPlaceholderOnFormReady = (event: MessageEvent) => {
        if (event?.data?.eventName === 'onFormReady') {
            setDisplayPlaceholder(false)
        }
    }

    useEffect(() => {
        window.addEventListener('message', hideLoadingPlaceholderOnFormReady)
        return () => window.removeEventListener('message', hideLoadingPlaceholderOnFormReady)
    })

    return (
        <FormWrapper ref={ref as unknown as RefObject<HTMLDivElement>} id={`mg_form_${data.formId}`}>
            {!!data.introductoryText && <Introduction dangerouslySetInnerHTML={{ __html: data.introductoryText }} />}
            <FormTarget id={id} />
            {displayPlaceholder && <FormLoadingPlaceholder />}
        </FormWrapper>
    )
}

const FormWrapper = styled.div`
    max-width: 25rem;
    width: 100%;
    align-self: flex-start;
    background-color: var(--color-white);
    padding: 1.25rem;
    border-radius: 0.125rem;
    box-shadow: var(--box-shadow-less);
    margin: 0 auto;
`

const Introduction = styled.div`
    margin: 0 0 1.75rem;

    > * {
        margin: 0 0 0.75rem;
    }

    > h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: var(--color-black);
    }

    p {
        color: var(--color-grey-extra-dark);
    }
`

const FormTarget = styled.div`
    /* Form element styles */

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .hs-form-field:not(.hs-fieldtype-select) > label {
        display: none;
    }

    .hs-form fieldset {
        padding: 0;
        border: 0;
        margin: 0;
    }

    .hs-fieldtype-textarea,
    .hs-fieldtype-text {
        margin: 0 0 0.875rem;

        textarea,
        input {
            /* !important to override Hubspot inline styles */
            width: 100% !important;
            color: var(--color-grey-extra-dark);
            font-family: var(--font-headings);
            font-size: var(--font-size-small);
            font-weight: var(--font-weight-bold);
            line-height: inherit;
            padding: 0.375rem 0.75rem;
            border: 0.0625rem solid var(--color-grey);
            border-radius: 0.125rem;

            &.invalid {
                border-color: var(--color-red);
            }

            &:focus,
            &.invalid:focus {
                border-color: var(--color-green);
                outline: none;
            }

            &::placeholder {
                color: var(--color-grey);
            }
        }

        .hs-error-msg {
            display: block;
            color: var(--color-red);
            font-size: var(--font-size-small);
            margin: 0.25rem 0 0;
        }

        textarea {
            min-height: 8rem;
        }
    }

    .hs-fieldtype-select {
        margin: 0 0 0.375rem;

        + .hs-fieldtype-select {
            margin: 0 0 1.125rem;
        }

        label {
            color: var(--color-grey-extra-dark);
            font-size: var(--font-size-small);
        }

        legend {
            /* !important to override Hubspot inline styles */
            display: none !important;
        }

        select {
            appearance: none;
            display: block;
            max-width: 100%;
            /* !important to override Hubspot inline styles */
            width: 100% !important;
            background-color: #fff;
            background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%2351BBA0%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
            background-repeat: no-repeat, repeat;
            background-position: right 0.6875rem top 50%, 0 0;
            background-size: 0.625rem auto, 100%;
            color: var(--color-grey-extra-dark);
            font-size: var(--font-size-extra-small);
            line-height: 1.3;
            padding: 0.6em 1.4em 0.5em 0.8em;
            border: 0.0625rem solid var(--color-grey);
            border-radius: 0.125rem;
            box-shadow: 0 0.0625rem 0 0.0625rem rgba(0, 0, 0, 0.04);
            margin: 0;
        }

        select::-ms-expand {
            display: none;
        }

        select::placeholder {
            color: var(--color-grey);
        }

        select:hover {
            border-color: var(--color-grey-extra-dark);
        }

        select:focus {
            border-color: var(--color-green);
            outline: none;
        }
    }

    .hs-form-booleancheckbox label {
        display: flex;
        align-items: flex-start;

        input {
            /* !important to override Hubspot's occasional width property with very high specifity */
            width: 16px !important;
            flex-shrink: 0;
            margin: 0.375rem 0 0;
        }

        input + span {
            color: var(--color-black);
            font-size: var(--font-size-small);
            margin: 0 0 0 1.25rem;

            a {
                color: var(--color-green-dark);
            }
        }
    }

    .hs-form-required {
        display: none;
    }

    .hs-submit {
        position: relative;
        background-image: var(--gradient-red);
        border-radius: 2rem;
        margin: 1.375rem 0 0;

        input[type='submit'] {
            appearance: none;
            width: 100%;
            background-color: transparent;
            color: var(--color-white);
            font-size: var(--font-size-small);
            line-height: 1.35;
            padding: 0.875rem 4rem 0.875rem 1.875rem;
            border: 0;
            margin: 0;
            cursor: pointer;
        }

        &:hover {
            &::before {
                transform: translateX(0.25rem);
            }
        }

        &::before {
            content: '';
            position: absolute;
            width: 1.25rem;
            height: 1.25rem;
            top: calc(50% - 0.625rem);
            right: 1.375rem;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 96 96'%3E%3Cg%3E%3Cpath fill='white' d='M12,52h62.344L53.172,73.172c-1.562,1.562-1.562,4.094,0,5.656c1.562,1.562,4.095,1.562,5.657,0l28-28 c1.562-1.562,1.562-4.095,0-5.656l-28-28C58.048,16.391,57.024,16,56,16c-1.023,0-2.047,0.391-2.828,1.172 c-1.562,1.562-1.562,4.095,0,5.656L74.344,44H12c-2.209,0-4,1.791-4,4S9.791,52,12,52z'/%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: 1.25rem 1.25rem;
            transition: transform 250ms ease;
        }
    }

    .submitted-message {
        color: var(--color-black);
        /* !important to override Hubspot inline styles */
        text-align: left;
    }
`

export default Form
