import { BijtellingYear } from '@mistergreen/shared/utilities/bijtelling'
import { LinkData } from '../../components/core/link'

export interface CarEditionSpecificationLabels {
    editionLinkTemplate: string
    fiscalValueLabel: string
    fiscalValueDescription: string
    fiscalValueTemplate: string
    leasePriceFolMinimumLabel: string
    leasePriceFolMinimumDescription: string
    leasePriceFolMinimumTemplate: string
    bijtellingCurrentYearLabel: string
    bijtellingCurrentYearDescription: string
    bijtellingCurrentYearTemplate: string
    bijtellingNextYearLabel: string
    bijtellingNextYearDescription: string
    bijtellingNextYearTemplate: string
    rangeWltpInKmLabel: string
    rangeWltpInKmDescription: string
    rangeWltpInKmTemplate: string
    rangeRealisticInKmLabel: string
    rangeRealisticInKmDescription: string
    rangeRealisticInKmTemplate: string
    accelerationFrom0To100KmInSecondsLabel: string
    accelerationFrom0To100KmInSecondsDescription: string
    accelerationFrom0To100KmInSecondsTemplate: string
    topSpeedInKmLabel: string
    topSpeedInKmDescription: string
    topSpeedInKmTemplate: string
    seatCountLabel: string
    seatCountDescription: string
    seatCountTemplate: string
    expectedDeliveryTimeLabel: string
    expectedDeliveryTimeDescription: string
    motorDescriptionLabel: string
    motorDescriptionDescription: string
    recommendationLabel: string
    recommendationDescription: string
    editionInformationLinkLabel: string
    editionInformationLinkDescription: string
}

export enum LabelType {
    Label,
    Description,
    Template,
}

export interface CarEditionData {
    editionName: string
    isPreferredEdition: boolean
    accelerationFrom0To100KmInSeconds: number
    topSpeedInKm: number
    fiscalValue: number
    leasePriceFolMinimum: number
    rangeRealisticInKm: number
    rangeWltpInKm: number
    seatCount: number
    expectedDeliveryTime: string
    motorDescription: string
    recommendation: string
    editionInformationLink: { linkArray: LinkData[] }[]
    editionQueryParamsString?: string
}

export const getLabel = (
    carEditionSpecificationLabels: CarEditionSpecificationLabels,
    labelKey: keyof CarEditionData,
    labelType: LabelType
): string => {
    let labelTypeString

    if (labelType === LabelType.Label) {
        labelTypeString = 'Label'
    } else if (labelType === LabelType.Description) {
        labelTypeString = 'Description'
    } else if (labelType === LabelType.Template) {
        labelTypeString = 'Template'
    }

    return carEditionSpecificationLabels[`${labelKey}${labelTypeString}` as keyof CarEditionSpecificationLabels]
}

export const getHeadingLabels = (
    carEditionSpecificationLabels: CarEditionSpecificationLabels,
    keyToRender: keyof CarEditionData
): { description: string; label: string } => {
    const label = getLabel(carEditionSpecificationLabels, keyToRender, LabelType.Label)
    const description = getLabel(carEditionSpecificationLabels, keyToRender, LabelType.Description)

    return { description, label }
}

export const getBijtellingLabels = (
    carEditionSpecificationLabels: CarEditionSpecificationLabels,
    bijtellingYear: BijtellingYear
): { description: string; label: string } => {
    const label =
        bijtellingYear === 2021
            ? carEditionSpecificationLabels.bijtellingCurrentYearLabel
            : carEditionSpecificationLabels.bijtellingNextYearLabel
    const description =
        bijtellingYear === 2021
            ? carEditionSpecificationLabels.bijtellingCurrentYearDescription
            : carEditionSpecificationLabels.bijtellingNextYearDescription

    return { description, label }
}
