import React from 'react'
import styled from 'styled-components'

interface CopyButtonProps {
    copyText: string
    buttonText: string
}

const CopyButton = ({ copyText, buttonText }: CopyButtonProps): JSX.Element => {
    const copy = () => {
        const el = document.createElement('textarea')
        el.value = copyText
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
    }

    return (
        <Container>
            <p>{copyText}</p>
            <button onClick={() => copy()}>{buttonText}</button>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    p {
        text-decoration: underline 0.0625rem dotted;
        margin: 0.5rem 2rem 0.5rem 0;
    }

    button {
        height: 2.25rem;
        width: 100%;
        background-color: transparent;
        color: var(--color-white);
        font-size: var(--font-size-small);
        padding: 0.375rem 0.75rem;
        border: 0.125rem solid var(--color-white);
        border-radius: 0.25rem;
        margin: 0;
        cursor: pointer;
        transition: all 0.15s ease-in-out;

        &:hover {
            background-color: var(--color-white);
            color: var(--color-green-dark);
        }

        @media (min-width: 30rem) {
            height: auto;
            width: auto;
        }
    }
`

export default CopyButton
