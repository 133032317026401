import { ConfigurationModel } from '../../utilities/create-pages/fetch-model-information'
import { CraftData, LocaleObject, CraftEntry, CraftGlobalSet } from '../../utilities/general/types'
import Footer from '../core/footer'
import { extractCurrentEntry, extractGlobalSet } from '../../utilities/craft-data/extract-data'
import { getBlogFeed } from '../../utilities/general/blog-feed'
import GlobalStyles from '../core/global-styles'
import { graphql } from 'gatsby'
import handleCraftComponents from '../../utilities/craft-data/handle-craft-components'
import Header from '../core/header'
import OuterWrapper from '../core/outer-wrapper'
import PageWrapper from '../ui/page-wrapper'
import preparePageData from '../../utilities/craft-data/prepare-page-data'
import React from 'react'
import Section from '../core/section'
import SEO from '../core/seo'

export interface PageContext {
    allEntries: CraftEntry[]
    appEntries: CraftEntry[]
    blogEntries: CraftEntry[]
    cookieConsent: string
    configurationModels: ConfigurationModel[]
    currentLocale: LocaleObject
    environment: string
    formData: any[]
    globalSets: CraftGlobalSet[]
    id: string
    siteId: number
    sourceId: string
}

const DefaultPage = ({ data, pageContext }: { data: CraftData; pageContext: PageContext }): JSX.Element => {
    const currentEntry = extractCurrentEntry(data)
    const { footerProps, headerProps, seoProps } = preparePageData({ ...pageContext, currentEntry })

    return (
        <OuterWrapper currentLocale={pageContext.currentLocale}>
            <SEO {...seoProps} />
            <GlobalStyles />
            <PageWrapper>
                <Header {...headerProps} />
                <main>
                    {handleCraftComponents(currentEntry).map((component: any, index: number) => (
                        <Section
                            blogFeed={getBlogFeed({
                                blogEntries: pageContext.blogEntries,
                                globalSets: pageContext.globalSets,
                            })}
                            carEditionSpecificationLabels={extractGlobalSet(
                                pageContext.globalSets,
                                'carEditionSpecificationLabels'
                            )}
                            configurationModels={pageContext.configurationModels}
                            data={component}
                            formData={pageContext.formData}
                            sectionIndex={index}
                            key={index}
                            pageId={currentEntry.id}
                            trustpilotGlobals={extractGlobalSet(pageContext.globalSets, 'trustpilotWidget')}
                        />
                    ))}
                </main>
                <Footer {...footerProps} />
            </PageWrapper>
        </OuterWrapper>
    )
}

export const query = graphql`
    query getCraftPage($siteId: Int!, $id: String!) {
        allCraftEntryInterface(filter: { siteId: { eq: $siteId }, id: { eq: $id } }) {
            nodes {
                typeHandle
                id
                sourceId
                title
                slug
                url
                ... on Craft_homepage_homepage_Entry {
                    seoTitle
                    description
                    noindex
                    keywords {
                        keyword
                    }
                    seoImage {
                        filename
                        dateUpdated
                    }
                    components {
                        ... on Craft_components_component_BlockType {
                            ...SectionFragment
                        }
                        ... on Craft_components_reusableComponent_BlockType {
                            typeHandle
                            selectedComponent {
                                ... on Craft_reusableComponents_reusableComponents_Entry {
                                    ...ReusableComponentFragment
                                }
                            }
                        }
                    }
                }
                ... on Craft_contact_contact_Entry {
                    seoTitle
                    description
                    noindex
                    keywords {
                        keyword
                    }
                    seoImage {
                        filename
                        dateUpdated
                    }
                    components {
                        ... on Craft_components_component_BlockType {
                            ...SectionFragment
                        }
                        ... on Craft_components_reusableComponent_BlockType {
                            typeHandle
                            selectedComponent {
                                ... on Craft_reusableComponents_reusableComponents_Entry {
                                    ...ReusableComponentFragment
                                }
                            }
                        }
                    }
                }
                ... on Craft_pages_pages_Entry {
                    seoTitle
                    description
                    noindex
                    keywords {
                        keyword
                    }
                    seoImage {
                        filename
                        dateUpdated
                    }
                    components {
                        ... on Craft_components_component_BlockType {
                            ...SectionFragment
                        }
                        ... on Craft_components_reusableComponent_BlockType {
                            typeHandle
                            selectedComponent {
                                ... on Craft_reusableComponents_reusableComponents_Entry {
                                    ...ReusableComponentFragment
                                }
                            }
                        }
                    }
                }
                ... on Craft_posts_posts_Entry {
                    seoTitle
                    description
                    noindex
                    keywords {
                        keyword
                    }
                    seoImage {
                        filename
                        dateUpdated
                    }
                    components {
                        ... on Craft_components_component_BlockType {
                            ...SectionFragment
                        }
                        ... on Craft_components_reusableComponent_BlockType {
                            typeHandle
                            selectedComponent {
                                ... on Craft_reusableComponents_reusableComponents_Entry {
                                    ...ReusableComponentFragment
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export default DefaultPage
