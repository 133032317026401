import {
    CarEditionData,
    CarEditionSpecificationLabels,
    getHeadingLabels,
} from '../../utilities/car-edition-specification/labels'
import FlexibleLink from '../core/link'
import { Locale } from '../../utilities/general/locale'
import { prepareEditionColumnData } from '../../utilities/car-edition-specification/data'
import React, { useContext } from 'react'

interface CarSpecificationMobileRowProps {
    carEditionSpecificationLabels: CarEditionSpecificationLabels
    edition: CarEditionData
    keyToRender: keyof CarEditionData
}

const CarSpecificationMobileRow = ({
    carEditionSpecificationLabels,
    edition,
    keyToRender,
}: CarSpecificationMobileRowProps): JSX.Element => {
    const locale = useContext(Locale)

    const { label, description } = getHeadingLabels(carEditionSpecificationLabels, keyToRender)
    let rowHeading = <h4>{label}</h4>

    if (description) {
        rowHeading = (
            <details>
                <summary>
                    <h4>{label}</h4>
                </summary>
                <div dangerouslySetInnerHTML={{ __html: description }} />
            </details>
        )
    }

    if (keyToRender === 'editionInformationLink') {
        const { linkArray } = edition.editionInformationLink[0]

        return linkArray.length ? (
            <div>
                {rowHeading}
                <p>
                    <FlexibleLink data={linkArray[0]} />
                </p>
            </div>
        ) : (
            <></>
        )
    }

    return (
        <div>
            {rowHeading}
            <p>{prepareEditionColumnData({ carEditionSpecificationLabels, edition, keyToRender, locale })}</p>
        </div>
    )
}

export default CarSpecificationMobileRow
