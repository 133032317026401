const scriptSources: { [key: string]: Promise<void> } = {}

export function loadScript(scriptSource: string): Promise<void> {
    if (!document) {
        return new Promise(resolve => resolve())
    }
    if (!scriptSources.hasOwnProperty(scriptSource)) {
        const script = document.createElement('script')
        script.src = scriptSource
        script.async = true
        script.type = 'text/javascript'
        script.charset = 'utf-8'

        scriptSources[scriptSource] = new Promise((resolve, reject) => {
            script.addEventListener('load', () => {
                resolve()
            })

            script.addEventListener('error', () => {
                script.remove()
                delete scriptSources[scriptSource]
                reject()
            })

            document.body.appendChild(script)
        })
    }

    return scriptSources[scriptSource]
}
