import { isProduction } from './environment'

export type DataLayerContent = { [key: string]: any }

export class DataLayer {
    static push(content: DataLayerContent) {
        // make sure this is executed in a browser
        if (typeof window === 'undefined') {
            return
        }

        if (!isProduction) {
            console.log('dataLayer', content)
        }

        window['dataLayer'] = window['dataLayer'] || []
        window['dataLayer'].push(content)
    }
}
