import { graphql } from 'gatsby'
import Link, { LinkData } from '../core/link'
import { listStyles, listItemStyles, greenButton, defaultButton } from './_shared-styles'
import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'

export interface TextProps {
    data: {
        text: string
        linkArray: LinkData[]
    }
    layoutOverride?: string
}

const Text = ({ data, layoutOverride }: TextProps): JSX.Element => {
    // Replace occurences of --- in links to apps with slashes
    const text = data.text.replace(/<a.+?href=['"].*?---.*?['"]/g, match => match.replace(/---/g, '/'))

    // Insert the Twitter widget JS if the page includes a Twitter block
    useEffect(() => {
        const tweet = document.querySelector('.twitter-tweet')

        if (tweet) {
            const script = document.createElement('script')
            script.setAttribute('src', 'https://platform.twitter.com/widgets.js')
            tweet.appendChild(script)
        }
    }, [])

    return (
        <Wrapper>
            <Container
                dangerouslySetInnerHTML={{ __html: text }}
                withSpecialListItems={layoutOverride === 'wideRight'}
            />
            {!!data.linkArray && !!data.linkArray.length && (
                <LinkContainer>
                    <OuterLink>
                        <Link data={data.linkArray[0]} />
                    </OuterLink>
                </LinkContainer>
            )}
            {!!data.linkArray && data.linkArray.length === 2 && (
                <LinkContainer>
                    <OuterLink isSecondary={true}>
                        <Link data={data.linkArray[1]} />
                    </OuterLink>
                </LinkContainer>
            )}
        </Wrapper>
    )
}

export default Text

const Wrapper = styled.div`
    margin: 0 auto;
`

const Container = styled.div<{ withSpecialListItems: boolean }>`
    max-width: var(--max-single-element-width);

    &:not(:only-child) {
        margin: 0;
    }

    p {
        font-size: var(--font-size-medium);
    }

    blockquote {
        font-size: var(--font-size-large);
        line-height: var(--line-height-blockquotes);
    }

    ul {
        ${listStyles}
    }

    li {
        ${listItemStyles}
    }

    ${props =>
        !props.withSpecialListItems &&
        css`
            ul {
                padding: 0 0 0 1.25rem;
                list-style: disc outside;

                li {
                    font-size: var(--font-size-medium);
                    padding: 0 0 0 0.25rem;
                }

                &::before,
                li::before {
                    content: none;
                }
            }
        `}

    ol {
        ${listItemStyles}
        padding: 0 0 0 1.25rem;

        li {
            font-size: var(--font-size-medium);
            padding: 0 0 0 0.25rem;
            margin: 0 0 0.25rem;
        }

        li::before,
        &::before {
            content: none;
        }
    }

    > * {
        margin: 0;
    }

    > * + *,
    > ol:not(:first-child),
    > ul:not(:first-child) {
        margin-top: var(--spacing-elements);
    }

    hr {
        color: inherit;
        border-style: solid;
        border-bottom: 0;
        margin: var(--spacing-elements-more) 0;
    }

    figure {
        position: relative;
        overflow: hidden;
        padding-top: 56.25%;

        iframe {
            max-width: 100%;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border: 0;
        }
    }
`

const LinkContainer = styled.div`
    width: 100%;
    max-width: var(--max-single-element-width);
    display: flex;
    margin: 2.5rem 0 0;

    & + & {
        margin: 0.625rem 0 0;
    }
`

const OuterLink = styled.div<{ isSecondary?: boolean }>`
    @media (min-width: 24rem) {
        min-width: 19.5rem;
    }

    ${props =>
        props.isSecondary
            ? css`
                  ${defaultButton}
              `
            : css`
                  ${greenButton}
              `}
`

export const query = graphql`
    fragment TextFragment on Craft_components_text_BlockType {
        text
        linkArray {
            ... on Craft_linkArray_internalLink_BlockType {
                text
                entry {
                    typeHandle
                    slug
                }
            }
            ... on Craft_linkArray_externalLink_BlockType {
                text
                linkUrl
            }
        }
    }

    fragment TextReusableComponentFragment on Craft_componentType_text_BlockType {
        text
        linkArray {
            ... on Craft_linkArray_internalLink_BlockType {
                text
                entry {
                    typeHandle
                    slug
                }
            }
            ... on Craft_linkArray_externalLink_BlockType {
                text
                linkUrl
            }
        }
    }
`
