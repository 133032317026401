import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

export interface DescriptionListData {
    optionalListHeading: string
    list: [
        {
            heading: string
            description: string
        }
    ]
}

export interface DescriptionListProps {
    data: DescriptionListData
}

const DescriptionList = ({ data }: DescriptionListProps): JSX.Element => (
    <Container>
        {!!data.optionalListHeading && <OptionalHeading>{data.optionalListHeading}</OptionalHeading>}
        <DescriptionListContainer>
            {data.list.map((item, index) => (
                <React.Fragment key={index}>
                    <DescriptionTerm>{item.heading}</DescriptionTerm>
                    <DescriptionDetails dangerouslySetInnerHTML={{ __html: item.description }} />
                </React.Fragment>
            ))}
        </DescriptionListContainer>
    </Container>
)

export default DescriptionList

const minBreakpoint = '27.5rem'

const Container = styled.div`
    align-self: start;
    position: relative;
    z-index: 1;
    background-color: var(--color-white);
    padding: 1.75rem 1.75rem 2rem;
    border-radius: 0.125rem;
    box-shadow: var(--box-shadow-more);
`

const OptionalHeading = styled.h3`
    font-size: var(--font-size-extra-large);
    margin: 0 0 2rem;
`

const DescriptionListContainer = styled.dl`
    margin: 0;

    @media (min-width: ${minBreakpoint}) {
        display: grid;
        grid-template-columns: 2fr 3fr;
        grid-gap: 0.875rem 2.5rem;
    }
`

const DescriptionTerm = styled.dt`
    opacity: 0.8;
    color: var(--color-black);
`

const DescriptionDetails = styled.dd`
    color: var(--color-black);
    margin: 0 0 1rem;

    &:last-of-type {
        margin: 0;
    }

    @media (min-width: ${minBreakpoint}) {
        margin: 0;
    }

    > p {
        margin: 0;

        a {
            color: var(--color-green-dark);
            text-decoration: none;
            word-break: break-all;
        }
    }
`

export const query = graphql`
    fragment DescriptionListFragment on Craft_components_descriptionList_BlockType {
        optionalListHeading
        list {
            ... on Craft_list_BlockType {
                heading
                description
            }
        }
    }

    fragment DescriptionListReusableComponentFragment on Craft_componentType_descriptionList_BlockType {
        optionalListHeading
        list {
            ... on Craft_list_BlockType {
                heading
                description
            }
        }
    }
`
