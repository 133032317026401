import styled from 'styled-components'

const FullScreenBackground = styled.div`
    *:not(&) + & {
        min-height: 100vh;
        margin: var(--vertical-spacing) 0 0;
    }

    > :first-child {
        min-height: 100vh;
        max-width: 100%;
        padding: 0;
        margin: 0;
    }
`

export default FullScreenBackground
