import { graphql } from 'gatsby'
import Link, { LinkData } from '../core/link'
import React from 'react'
import styled, { css } from 'styled-components'

export interface BannerData {
    bannerBackground: 'green' | 'purple' | 'black'
    bannerLinks: [{ linkArray: LinkData[] }]
}

export interface BannerProps {
    data: BannerData
}

const Banner = ({ data }: BannerProps): JSX.Element => (
    <Container color={data.bannerBackground}>
        <InnerContainer>
            {data.bannerLinks.map((link, index) => (
                <li key={index}>
                    <Link data={link.linkArray[0]} />
                </li>
            ))}
        </InnerContainer>
    </Container>
)

interface ContainerProps {
    color: 'green' | 'purple' | 'black'
}

const Container = styled.nav<ContainerProps>`
    background-image: var(--gradient-green);
    color: var(--color-white);

    a {
        color: inherit;
    }

    ${props =>
        props.color === 'purple' &&
        css`
            background-image: var(--gradient-purple);
        `}

    ${props =>
        props.color === 'black' &&
        css`
            background-image: var(--gradient-black);
        `}
`

const InnerContainer = styled.ul`
    max-width: var(--page-width);
    list-style: none;
    padding: 1.25rem var(--gutter-width) 0.25rem;
    margin: 0 auto;
    text-align: center;

    > li {
        display: inline-block;
        margin: 0 1.75rem 1rem;

        a {
            opacity: 0.7;
            transition: opacity 0.15s ease-in-out;

            &:hover {
                opacity: 1;
            }

            &[aria-current] {
                opacity: 1;
            }
        }
    }
`

export default Banner

export const query = graphql`
    fragment LinkBannerFragment on Craft_components_linkBanner_BlockType {
        bannerBackground
        bannerLinks {
            ... on Craft_bannerLinks_BlockType {
                ...BannerLinksFragment
            }
        }
    }

    fragment LinkBannerReusableComponentFragment on Craft_componentType_linkBanner_BlockType {
        bannerBackground
        bannerLinks {
            ... on Craft_bannerLinks_BlockType {
                ...BannerLinksFragment
            }
        }
    }

    fragment BannerLinksFragment on Craft_bannerLinks_BlockType {
        linkArray {
            ... on Craft_linkArray_internalLink_BlockType {
                text
                entry {
                    typeHandle
                    slug
                }
            }
            ... on Craft_linkArray_externalLink_BlockType {
                text
                linkUrl
            }
        }
    }
`
