import { CarData, prepareEditionLink } from '../../utilities/car-edition-specification/data'
import { CarEditionData, CarEditionSpecificationLabels } from '../../utilities/car-edition-specification/labels'
import CarSpecificationMobileBijtellingRow from './car-specification-mobile-bijtelling-row'
import FlexibleLink from '../core/link'
import { LocaleObject } from '../../utilities/general/types'
import React from 'react'
import CarSpecificationMobileRow from './car-specification-mobile-row'

interface CarSpecificationMobileCardProps {
    carEditionSpecificationLabels: CarEditionSpecificationLabels
    currentLocale: LocaleObject
    edition: CarEditionData
    model: CarData
}

const CarSpecificationMobileCard = ({
    carEditionSpecificationLabels,
    currentLocale,
    edition,
    model,
}: CarSpecificationMobileCardProps): JSX.Element => {
    const editionLinkData = prepareEditionLink({
        edition,
        editionLinkTemplate: carEditionSpecificationLabels.editionLinkTemplate,
        model,
    })

    return (
        <>
            <header>
                <h3>{edition.editionName}</h3>
                <FlexibleLink data={editionLinkData} />
            </header>
            <CarSpecificationMobileRow
                carEditionSpecificationLabels={carEditionSpecificationLabels}
                keyToRender="fiscalValue"
                edition={edition}
            />
            <CarSpecificationMobileRow
                carEditionSpecificationLabels={carEditionSpecificationLabels}
                keyToRender="leasePriceFolMinimum"
                edition={edition}
            />
            <CarSpecificationMobileRow
                carEditionSpecificationLabels={carEditionSpecificationLabels}
                keyToRender="rangeWltpInKm"
                edition={edition}
            />
            <CarSpecificationMobileRow
                carEditionSpecificationLabels={carEditionSpecificationLabels}
                keyToRender="rangeRealisticInKm"
                edition={edition}
            />
            <CarSpecificationMobileRow
                carEditionSpecificationLabels={carEditionSpecificationLabels}
                keyToRender="accelerationFrom0To100KmInSeconds"
                edition={edition}
            />
            <CarSpecificationMobileRow
                carEditionSpecificationLabels={carEditionSpecificationLabels}
                keyToRender="topSpeedInKm"
                edition={edition}
            />
            <CarSpecificationMobileRow
                carEditionSpecificationLabels={carEditionSpecificationLabels}
                keyToRender="seatCount"
                edition={edition}
            />
            <CarSpecificationMobileRow
                carEditionSpecificationLabels={carEditionSpecificationLabels}
                keyToRender="expectedDeliveryTime"
                edition={edition}
            />
            <CarSpecificationMobileRow
                carEditionSpecificationLabels={carEditionSpecificationLabels}
                keyToRender="motorDescription"
                edition={edition}
            />
            <CarSpecificationMobileRow
                carEditionSpecificationLabels={carEditionSpecificationLabels}
                keyToRender="recommendation"
                edition={edition}
            />
            <CarSpecificationMobileRow
                carEditionSpecificationLabels={carEditionSpecificationLabels}
                keyToRender="editionInformationLink"
                edition={edition}
            />
        </>
    )
}

export default CarSpecificationMobileCard
