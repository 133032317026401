import { graphql } from 'gatsby'
import Link, { LinkData } from '../core/link'
import React from 'react'
import styled, { css } from 'styled-components'
import Text from './text'
import { typographyMaxWidthBreakpoint } from '../core/global-styles'
import { whiteButton } from './_shared-styles'

export interface BannerData {
    color: 'green' | 'purple' | 'black'
    text: string
    optionalBannerButtons: LinkData[]
}

export interface BannerProps {
    data: BannerData
    asTopBanner?: boolean
}

const Banner = ({ data, asTopBanner = false }: BannerProps): JSX.Element => (
    <Container color={data.color} asTopBanner={asTopBanner}>
        <InnerContainer>
            <Text data={{ text: data.text, linkArray: [] }} />
            <ButtonGroup>
                {!!data.optionalBannerButtons?.length &&
                    data.optionalBannerButtons.map((button, index) => (
                        <LinkContainer key={index}>
                            <Link data={button} />
                        </LinkContainer>
                    ))}
            </ButtonGroup>
        </InnerContainer>
    </Container>
)

interface ContainerProps {
    color: 'green' | 'purple' | 'black'
    asTopBanner: boolean
}

const Container = styled.div<ContainerProps>`
    background-image: var(--gradient-green);
    color: var(--color-white);

    a {
        color: inherit;
        text-decoration: underline;
        font-weight: bold;
    }

    ${props =>
        props.asTopBanner &&
        css`
            padding: var(--header-height) 0 0;

            h1:only-child {
                font-size: 3rem;
                font-weight: var(--font-weight-medium);
                text-align: center;
                margin: 2rem auto 4rem;

                @media (max-width: ${typographyMaxWidthBreakpoint}) {
                    font-size: var(--font-size-extra-extra-large);
                    margin: 0 auto 2.75rem;
                }
            }
        `}

    ${props =>
        props.color === 'purple' &&
        css`
            background-image: var(--gradient-purple);
        `}

  ${props =>
        props.color === 'black' &&
        css`
            background-image: var(--gradient-black);
        `}
`

const InnerContainer = styled.div`
    max-width: var(--page-width);
    padding: 2rem var(--gutter-width) 1rem;
    margin: 0 auto;

    :first-child [class^='text__Container'] {
        max-width: initial;

        * {
            max-width: 80ch;
        }

        > blockquote:only-child {
            margin-left: auto;
            margin-right: auto;
        }

        figure {
            padding: 0;
        }
    }

    > *,
    > div > div {
        margin-left: 0;
    }

    blockquote {
        border-color: var(--color-white);
    }
`

const ButtonGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 1.25rem 0 0;

    @media (max-width: 40rem) {
        display: block;
    }
`

const LinkContainer = styled.div`
    ${whiteButton}
    margin: 1rem .5rem;

    @media (max-width: 40rem) {
        max-width: 24rem;
        margin: 0 0 0.875rem;
    }
`

export default Banner

export const query = graphql`
    fragment BannerFragment on Craft_components_banner_BlockType {
        color: backgroundColour
        text: bannerText
        optionalBannerButtons {
            ... on Craft_optionalBannerButtons_internalLink_BlockType {
                text
                entry {
                    typeHandle
                    slug
                }
            }
            ... on Craft_optionalBannerButtons_externalLink_BlockType {
                text
                linkUrl
            }
        }
    }

    fragment BannerReusableComponentFragment on Craft_componentType_banner_BlockType {
        color: backgroundColour
        text: bannerText
        optionalBannerButtons {
            ... on Craft_optionalBannerButtons_internalLink_BlockType {
                text
                entry {
                    typeHandle
                    slug
                }
            }
            ... on Craft_optionalBannerButtons_externalLink_BlockType {
                text
                linkUrl
            }
        }
    }
`
