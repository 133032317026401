import { useState, useRef, useEffect, Dispatch, SetStateAction } from 'react'

export interface IntersectionObserverOptions {
    root?: Element | null
    rootMargin?: string
    threshold?: number | number[]
}

type UseIntersectionObserverData = [Dispatch<SetStateAction<Element | null>>, unknown, boolean]

const useIntersectionObserver = ({
    root = null,
    rootMargin = '0px',
    threshold = 0,
}: IntersectionObserverOptions): UseIntersectionObserverData => {
    const [entry, setEntry] = useState<IntersectionObserverEntry | unknown>({})
    const [node, setNode] = useState<Element | null>(null)

    const observer = useRef<IntersectionObserver | null>(null)
    const hasErrored = useRef<boolean>(false)

    useEffect(() => {
        if (observer.current) {
            observer.current.disconnect()
        }

        try {
            observer.current = new window.IntersectionObserver(([entry]) => setEntry(entry), {
                root,
                rootMargin,
                threshold,
            })

            const { current: currentObserver } = observer

            if (node) {
                currentObserver.observe(node)
            }

            return () => currentObserver.disconnect()
        } catch {
            hasErrored.current = true
        }
    }, [node, root, rootMargin, threshold])

    return [setNode, entry, hasErrored.current]
}

export default useIntersectionObserver
