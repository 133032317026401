import { graphql } from 'gatsby'
import React from 'react'
import styled, { css } from 'styled-components'

import { primaryButton } from './_shared-styles'
import Image, { ImageData, ImageVersion } from './image'
import Link, { LinkData } from '../core/link'

export interface CallToActionHeroListItem {
    listItem: string
}

export interface CallToActionHeroData {
    heroBackground: ImageData[]
    positionOfTopElement: TextPosition
    heroMainHeading: string
    primaryText: string
    secondaryText: string
    primaryButton: LinkData[]
    secondaryButton: LinkData[]
}

export type TextPosition = 'left' | 'right'

export interface CallToActionHeroProps {
    data: CallToActionHeroData
}

const CallToActionHero = ({ data }: CallToActionHeroProps): JSX.Element => (
    <OuterContainer position={data.positionOfTopElement}>
        <Image data={{ image: data.heroBackground }} version={ImageVersion.FullWidth} />
        <InnerContainer position={data.positionOfTopElement}>
            <OverlayingElement>
                <PrimaryTextContainer>
                    <PrimaryHeading>{data.heroMainHeading}</PrimaryHeading>
                    {data?.primaryText && <div dangerouslySetInnerHTML={{ __html: data.primaryText }}></div>}
                </PrimaryTextContainer>
                {data?.secondaryText && (
                    <SecondaryTextContainer
                        dangerouslySetInnerHTML={{ __html: data.secondaryText }}
                    ></SecondaryTextContainer>
                )}
                {data?.primaryButton?.[0] && (
                    <PrimaryLinkContainer>
                        <Link data={data.primaryButton[0]} />
                    </PrimaryLinkContainer>
                )}
                {data?.secondaryButton?.[0] && (
                    <SecondaryLinkContainer>
                        <Link data={data.secondaryButton[0]} />
                    </SecondaryLinkContainer>
                )}
            </OverlayingElement>
        </InnerContainer>
    </OuterContainer>
)

const OuterContainer = styled.div<{ position: TextPosition }>`
    overflow: hidden;
    z-index: 1;
    @media screen and (max-width: 699px) {
        > picture > img {
            filter: contrast(0.6);
        }
    }

    @media screen and (min-width: 700px) {
        position: relative;
        padding: 0;
        display: flex;
        align-items: stretch;
        justify-content: center;

        > picture,
        > picture > img {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        > picture > img {
            object-fit: cover;
        }
    }
`

const InnerContainer = styled.div<{ position: TextPosition }>`
    padding: 0 var(--gutter-width) 0 var(--gutter-width);
    margin: -3rem auto 0 auto;

    @media screen and (min-width: 700px) {
        display: flex;
        position: relative;
        width: 100%;
        max-width: var(--page-width);
        margin: 0 auto;
        padding-top: 6rem;
        padding-bottom: 2rem;
        ${props =>
            props.position === 'left'
                ? css`
                      justify-content: flex-start;
                  `
                : css`
                      justify-content: flex-end;
                  `};

        &:before {
            content: '';
            display: block;
            position: absolute;
            z-index: 1;
            width: calc(50vw + 700px);
            top: 0;
            height: 100%;

            ${props =>
                props.position === 'left'
                    ? css`
                          background: linear-gradient(
                              90deg,
                              rgba(0, 0, 0, 0.75) 0%,
                              rgba(0, 0, 0, 0.6) calc(100% - 250px),
                              rgba(0, 0, 0, 0) 100%
                          );
                          right: calc(100% - 700px);
                      `
                    : css`
                          background: linear-gradient(
                              90deg,
                              rgba(0, 0, 0, 0) 0%,
                              rgba(0, 0, 0, 0.6) 250px,
                              rgba(0, 0, 0, 0.75) 100%
                          );
                          left: calc(100% - 700px);
                      `};
        }
    }
`

const OverlayingElement = styled.div`
    max-width: 480px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 2;
`

const PrimaryHeading = styled.h1`
    margin-top: 0;
`

const PrimaryTextContainer = styled.div`
    background: var(--gradient-green);
    color: white;
    padding: 2rem;

    div {
        font-size: 1.2rem;
        > ul {
            padding-left: 1.5rem;
        }

        > *:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
`

const SecondaryTextContainer = styled.div`
    background: white;
    padding: 2rem;

    font-size: 1.1rem;
    box-shadow: var(--box-shadow-less);

    > ul {
        padding-left: 1.5rem;
    }

    > *:first-child {
        margin-top: 0;
        padding-top: 0;
    }
    > *:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
    }
`

const PrimaryLinkContainer = styled.div`
    ${primaryButton};
    display: inline-block;
    margin: 2.5rem auto 1.5rem auto;

    @media (min-width: 30rem) {
        padding: 0 2rem;
        max-width: 100%;
    }
`

const SecondaryLinkContainer = styled.div`
    display: inline-block;
    margin: 0.2rem auto 1.5rem auto;
    a {
        padding: 0 2rem;
        text-decoration: underline;
    }

    @media screen and (min-width: 700px) {
        a {
            color: white;
            text-shadow: 0px 0px 3px rgba(0, 0, 0, 1);
        }
    }
`

export default CallToActionHero

export const query = graphql`
    fragment CallToActionHeroFragment on Craft_components_callToActionHero_BlockType {
        heroBackground {
            filename
            dateUpdated
            height
            width
            hasFocalPoint
            focalPoint
        }
        positionOfTopElement
        heroMainHeading
        primaryText
        secondaryText
        primaryButton {
            ... on Craft_primaryButton_internalLink_BlockType {
                text
                entry {
                    typeHandle
                    slug
                }
            }
            ... on Craft_primaryButton_externalLink_BlockType {
                text
                linkUrl
            }
        }
        secondaryButton {
            ... on Craft_secondaryButton_internalLink_BlockType {
                text
                entry {
                    typeHandle
                    slug
                }
            }
            ... on Craft_secondaryButton_externalLink_BlockType {
                text
                linkUrl
            }
        }
    }

    fragment CallToActionHeroReusableComponentFragment on Craft_componentType_callToActionHero_BlockType {
        heroBackground {
            filename
            dateUpdated
            height
            width
            hasFocalPoint
            focalPoint
        }
        positionOfTopElement
        heroMainHeading
        primaryText
        secondaryText
        primaryButton {
            ... on Craft_primaryButton_internalLink_BlockType {
                text
                entry {
                    typeHandle
                    slug
                }
            }
            ... on Craft_primaryButton_externalLink_BlockType {
                text
                linkUrl
            }
        }
        secondaryButton {
            ... on Craft_secondaryButton_internalLink_BlockType {
                text
                entry {
                    typeHandle
                    slug
                }
            }
            ... on Craft_secondaryButton_externalLink_BlockType {
                text
                linkUrl
            }
        }
    }
`
