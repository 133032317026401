import { CarData } from '../../utilities/car-edition-specification/data'
import { CarEditionSpecificationLabels } from '../../utilities/car-edition-specification/labels'
import { CarSpecificationInnerTable } from '../car-edition-specification/car-specification-inner-table'
import CarSpecificationMobileCard from '../car-edition-specification/car-specification-mobile-card'
import { graphql } from 'gatsby'
import { Locale } from '../../utilities/general/locale'
import { LocaleObject } from '../../utilities/general/types'
import React, { useContext } from 'react'
import styled, { css } from 'styled-components'

export interface CarEditionSpecificationsData {
    optionalSpecificationHeading?: string
    optionalSpecificationIntroduction?: string
    usedModel: CarData[]
}

export interface CarEditionSpecificationsProps {
    carEditionSpecificationLabels: CarEditionSpecificationLabels
    data: CarEditionSpecificationsData
}

const CarEditionSpecifications = ({
    carEditionSpecificationLabels,
    data,
}: CarEditionSpecificationsProps): JSX.Element => {
    const currentLocale: LocaleObject = useContext(Locale)
    const model = data.usedModel[0]

    return (
        <OuterContainer>
            {!!data.optionalSpecificationHeading && (
                <OptionalHeading>{data.optionalSpecificationHeading}</OptionalHeading>
            )}
            {!!data.optionalSpecificationIntroduction && (
                <OptionalIntroduction dangerouslySetInnerHTML={{ __html: data.optionalSpecificationIntroduction }} />
            )}
            {!!model?.editions?.length && (
                <>
                    <SpecificationsTable>
                        <CarSpecificationInnerTable
                            carEditionSpecificationLabels={carEditionSpecificationLabels}
                            currentLocale={currentLocale}
                            model={model}
                        />
                    </SpecificationsTable>
                    <MobileSpecificationsTable>
                        {model.editions.map((edition, index) => (
                            <MobileEditionCard key={index} isPreferredEdition={edition.isPreferredEdition}>
                                <CarSpecificationMobileCard
                                    carEditionSpecificationLabels={carEditionSpecificationLabels}
                                    currentLocale={currentLocale}
                                    edition={edition}
                                    model={model}
                                />
                            </MobileEditionCard>
                        ))}
                    </MobileSpecificationsTable>
                </>
            )}
        </OuterContainer>
    )
}

export default CarEditionSpecifications

const OuterContainer = styled.div`
    --color-table-border: #e2e2e2;
    --color-table-heading: #eee;
    margin-bottom: var(--vertical-spacing);

    @media (max-width: 60rem) {
        margin-bottom: var(--spacing-elements-less);
    }
`

const OptionalHeading = styled.h2`
    width: 100%;
    max-width: 37rem;
    margin: 0 auto var(--spacing-elements-less);
`

const OptionalIntroduction = styled.div`
    width: 100%;
    max-width: 37rem;
    font-size: var(--font-size-medium);
    margin: 0 auto var(--spacing-elements-more);
`

const SpecificationsTable = styled.table`
    table-layout: fixed;
    width: 100%;
    position: relative;
    border-collapse: collapse;
    box-shadow: var(--box-shadow-less);

    thead {
        th {
            position: sticky;
            top: -0.125rem;
            background-color: var(--color-table-heading);

            &:first-child {
                font-size: var(--font-size-large);
                font-weight: 600;
                text-align: left;
                vertical-align: middle;
            }

            &[data-is-preferred='true'] {
                border-top-color: var(--color-green);
            }

            > a {
                font-size: var(--font-size);
            }
        }
    }

    tbody {
        th {
            text-align: left;

            details {
                > div {
                    font-size: var(--font-size);
                    font-weight: initial;
                }
            }
        }

        td {
            text-align: right;
        }

        tr:last-child td[data-is-preferred='true'] {
            border-bottom-color: var(--color-green);
        }
    }

    th,
    td {
        font-size: var(--font-size-medium);
        vertical-align: top;
        padding: 0.55rem 1rem;
        border: 0.125rem solid var(--color-table-border);

        :not(:last-child):not([data-is-preferred='true']) {
            border-right: 0;
        }

        &[data-is-preferred='true'] {
            border-left-color: var(--color-green);
            border-right-color: var(--color-green);
        }
    }

    @media (max-width: 60rem) {
        display: none;
    }
`

const MobileSpecificationsTable = styled.div`
    @media (min-width: 42rem) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;

        > * {
            width: calc(50% - 0.75rem);

            &:nth-child(2n-1):not(:last-child) {
                margin-right: 1.5rem;
            }
        }
    }

    @media (min-width: 60.0625rem) {
        display: none;
    }
`

const MobileEditionCard = styled.div<{ isPreferredEdition: boolean }>`
    position: relative;
    text-align: center;
    border: 0.125rem solid var(--color-table-border);
    box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.15);

    &:not(:first-child) {
        margin-top: 1.5rem;
    }

    @media (min-width: 42rem) {
        &:nth-child(2) {
            margin-top: 0;
        }
    }

    ${props =>
        props.isPreferredEdition &&
        css`
            border-color: var(--color-green);
        `}

    > header {
        position: sticky;
        top: 0;
        background-color: var(--color-table-heading);
        padding: 1.5rem;

        h3 {
            margin: 0;
        }
    }

    > div {
        padding: 1.5rem;

        &:not(:last-child) {
            border-bottom: 0.125rem solid var(--color-table-border);
        }

        h4,
        p {
            margin: 0;
        }

        details {
            summary {
                &:focus {
                    outline: none;
                }

                > * {
                    display: inline;
                }
            }

            > div {
                font-size: var(--font-size-small);
                padding: 0 0 0.75rem;
                border-bottom: 0.0625rem solid var(--color-grey-light);
                margin: 0 0 0.75rem;
            }
        }
    }
`

export const query = graphql`
    fragment CarEditionSpecificationsFragment on Craft_components_carEditionSpecifications_BlockType {
        optionalSpecificationHeading
        optionalSpecificationIntroduction
        usedModel {
            ... on Craft_cars_cars_Entry {
                ...CarFragment
            }
        }
    }

    fragment CarEditionSpecificationsReusableComponentFragment on Craft_componentType_carEditionSpecifications_BlockType {
        optionalSpecificationHeading
        optionalSpecificationIntroduction
        usedModel {
            ... on Craft_cars_cars_Entry {
                ...CarFragment
            }
        }
    }

    fragment CarFragment on Craft_cars_cars_Entry {
        brandName
        modelName
        modelInformationLink {
            ... on Craft_modelInformationLink_internalLink_BlockType {
                text
                entry {
                    typeHandle
                    slug
                }
            }
            ... on Craft_modelInformationLink_externalLink_BlockType {
                text
                linkUrl
            }
        }
        modelConfiguratorLink {
            ... on Craft_modelConfiguratorLink_internalLink_BlockType {
                text
                entry {
                    typeHandle
                    slug
                }
            }
            ... on Craft_modelConfiguratorLink_externalLink_BlockType {
                text
                linkUrl
            }
        }
        editions {
            ... on Craft_editions_edition_BlockType {
                editionName
                isPreferredEdition
                accelerationFrom0To100KmInSeconds
                topSpeedInKm
                fiscalValue
                leasePriceFolMinimum
                rangeRealisticInKm
                rangeWltpInKm
                seatCount
                expectedDeliveryTime
                motorDescription
                recommendation
                editionInformationLink {
                    ... on Craft_editionInformationLink_BlockType {
                        linkArray {
                            ... on Craft_linkArray_internalLink_BlockType {
                                text
                                entry {
                                    typeHandle
                                    slug
                                }
                            }
                            ... on Craft_linkArray_externalLink_BlockType {
                                text
                                linkUrl
                            }
                        }
                    }
                }
                editionQueryParamsString
            }
        }
    }
`
