import React from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'

interface MaterialInputProps {
    defaultValue: string
    label: string
    onChange(arg: string): void
    type?: string
}

const MaterialInput = ({ defaultValue, label, onChange, type }: MaterialInputProps): JSX.Element => {
    return (
        <TextFieldWrapper>
            <TextField
                label={label}
                type={type}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
                value={defaultValue}
            />
        </TextFieldWrapper>
    )
}

export default MaterialInput

const TextFieldWrapper = styled.div`
    * {
        width: 100%;
    }

    .MuiInput-underline {
        &:not(:active):before {
            border-bottom: 1px solid #333;
        }

        &:after {
            border-bottom: 1px solid #51bba0;
        }

        &:active {
            border-bottom: 1px solid #51bba0;
        }

        &:focus {
            background-color: transparent;
            border-bottom: 1px solid #51bba0;
        }

        &:hover:not(.Mui-disabled):not(.Mui-error):before {
            border-bottom: 1px solid #51bba0;
        }
    }

    label {
        white-space: nowrap;
        color: rgba(0, 0, 0, 0.54);
        font-family: var(--font-headings);
    }

    input {
        color: rgba(0, 0, 0, 0.7);
        font-family: var(--font-headings);
        font-weight: var(--font-weight-bold);
    }
`
