import { CarEditionSpecificationLabels, CarEditionData } from '../../utilities/car-edition-specification/labels'
import { CarData, prepareEditionColumnData, prepareEditionLink } from '../../utilities/car-edition-specification/data'
import FlexibleLink from '../core/link'
import { Locale } from '../../utilities/general/locale'
import React, { useContext } from 'react'

interface CarSpecificationColumnsProps {
    carEditionSpecificationLabels: CarEditionSpecificationLabels
    keyToRender: keyof CarEditionData
    model: CarData
}

const CarSpecificationColumns = ({
    carEditionSpecificationLabels,
    keyToRender,
    model,
}: CarSpecificationColumnsProps): JSX.Element => {
    const locale = useContext(Locale)

    const columns = model.editions.map((edition, index) => {
        const { isPreferredEdition } = edition

        if (keyToRender === 'editionInformationLink') {
            const { linkArray } = edition.editionInformationLink[0]

            return (
                <td key={index} data-is-preferred={isPreferredEdition}>
                    {linkArray.length ? <FlexibleLink data={linkArray[0]} /> : '-'}
                </td>
            )
        }

        const columnData = prepareEditionColumnData({ carEditionSpecificationLabels, edition, keyToRender, locale })

        if (keyToRender === 'editionName') {
            const editionLinkData = prepareEditionLink({
                edition,
                editionLinkTemplate: carEditionSpecificationLabels.editionLinkTemplate,
                model,
            })

            return (
                <th key={index} data-is-preferred={isPreferredEdition}>
                    <div>{columnData}</div>
                    <FlexibleLink data={editionLinkData} />
                </th>
            )
        }

        return (
            <td key={index} data-is-preferred={isPreferredEdition}>
                {columnData?.toString()}
            </td>
        )
    })

    return <>{columns}</>
}

export default CarSpecificationColumns
