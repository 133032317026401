import CopyButton from '../ui/copy-button'
import { getUserProfile, SignUpStatus, signUpUser } from '../../utilities/general/user'
import { graphql } from 'gatsby'
import Icon from '../ui/icon'
import React, { useEffect, useState } from 'react'
import SocialMediaShare from '../ui/social-media-share'
import styled from 'styled-components'
import useUserToken from '../../utilities/hooks/use-user-token'

export interface ReferralCodeData {
    greetingNotLoggedInUser: string
    introductionNotLoggedInUser: string
    privacyPolicyAcceptText: string
    loginEmailAddressLabel: string
    loginButtonText: string
    emailAlreadyKnownMessage: string
    greetingLoggedInUser: string
    introductionLoggedInUser: string
    copyButtonText: string
    shareViaEmailText: string
    shareViaWhatsAppText: string
    shareViaTwitterText: string
    shareViaLinkedInText: string
    socialMediaShareTitle: string
    socialMediaShareText: string
}

export interface ReferralCodeProps {
    data: ReferralCodeData
}

const ReferralCode = ({ data }: ReferralCodeProps): JSX.Element => {
    const [email, setEmail] = useState('')
    const [privacyPolicy, setPrivacyPolicy] = useState(false)
    const [signUpStatus, setSignUpStatus] = useState(SignUpStatus.Pending)
    const [{ firstName, referralLink }, setUserProfile] = useState({
        firstName: '',
        referralLink: '',
    })

    const checkReferralCode = async () => {
        const userProfile = await getUserProfile()
        setUserProfile(userProfile)
    }

    const userToken = useUserToken()

    useEffect(() => {
        checkReferralCode()
    }, [signUpStatus, userToken])

    const onLogin = async () => {
        const status = await signUpUser(email)
        setSignUpStatus(status)
    }

    return (
        <Container>
            <TextContainer>
                <Heading>
                    {referralLink
                        ? data.greetingLoggedInUser + (firstName ? ` ${firstName},` : '')
                        : data.greetingNotLoggedInUser}
                </Heading>
                <div
                    dangerouslySetInnerHTML={{
                        __html: referralLink ? data.introductionLoggedInUser : data.introductionNotLoggedInUser,
                    }}
                />
            </TextContainer>
            {referralLink ? (
                <ReferralContainer>
                    <CopyButton copyText={referralLink} buttonText={data.copyButtonText} />
                    <SocialMediaShare
                        shareViaEmailText={data.shareViaEmailText}
                        shareViaWhatsAppText={data.shareViaWhatsAppText}
                        shareViaTwitterText={data.shareViaTwitterText}
                        shareViaLinkedInText={data.shareViaLinkedInText}
                        emailSubject={data.socialMediaShareTitle}
                        description={data.socialMediaShareText}
                        link={referralLink}
                    />
                </ReferralContainer>
            ) : (
                <LogInForm onSubmit={e => e.preventDefault()}>
                    <label>
                        {data.loginEmailAddressLabel}
                        <input
                            type="text"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            disabled={signUpStatus === SignUpStatus.EmailAlreadyKnown}
                        />
                    </label>
                    <label>
                        <input
                            name="acceptedPrivacyPolicy"
                            type="checkbox"
                            checked={privacyPolicy}
                            onChange={e => setPrivacyPolicy(e.target.checked)}
                            disabled={signUpStatus === SignUpStatus.EmailAlreadyKnown}
                        />
                        <div dangerouslySetInnerHTML={{ __html: data.privacyPolicyAcceptText }} />
                    </label>
                    <button
                        onClick={onLogin}
                        disabled={!email || !privacyPolicy || signUpStatus === SignUpStatus.EmailAlreadyKnown}
                    >
                        {data.loginButtonText}
                    </button>
                </LogInForm>
            )}
            {signUpStatus === SignUpStatus.EmailAlreadyKnown && (
                <EmailAlreadyKnownMessage>
                    <Icon type={'heart'} />
                    {data.emailAlreadyKnownMessage}
                </EmailAlreadyKnownMessage>
            )}
        </Container>
    )
}

const Container = styled.div`
    background: var(--gradient-green);
    color: var(--color-white);
    padding: 1rem 1.5rem 2rem;
    border-radius: 0.125rem;
    box-shadow: var(--box-shadow-less);
`

const TextContainer = styled.div`
    max-width: 32rem;
`

const Heading = styled.h2`
    margin: 0;
`

const ReferralContainer = styled.div``

const LogInForm = styled.form`
    label {
        font-size: var(--font-size-small);

        &:last-of-type {
            display: flex;
            align-items: flex-start;
        }

        input[type='text'] {
            display: block;
            width: 100%;
            max-width: 16rem;
            color: var(--color-black);
            font-family: var(--font-headings);
            font-size: var(--font-size-extra-small);
            font-weight: var(--font-weight);
            line-height: inherit;
            padding: 0.25rem 0.625rem;
            border: 0;
            border-radius: 0.125rem;
            margin: 0.125rem 0 0.625rem;

            &.invalid {
                border-color: var(--color-red);
            }

            &:focus,
            &.invalid:focus {
                border-color: var(--color-green);
                outline: none;
            }

            &::placeholder {
                color: var(--color-grey);
            }

            &[disabled] {
                opacity: 0.6;
            }
        }

        input[type='checkbox'] {
            flex-shrink: 0;
            margin: 0.375rem 0.375rem 0 0;

            &[disabled] {
                opacity: 0.6;
            }
        }

        div {
            p {
                margin: 0;
            }

            a {
                color: inherit;
            }
        }
    }

    button {
        background-color: transparent;
        color: var(--color-white);
        font-size: var(--font-size-small);
        padding: 0.375rem 1.5rem;
        border: 0.125rem solid var(--color-white);
        border-radius: 0.25rem;
        margin: 1rem 0 0;
        cursor: pointer;
        transition: all 0.15s ease-in-out;

        &:hover:not([disabled]) {
            background-color: var(--color-white);
            color: var(--color-green-dark);
        }

        &[disabled] {
            opacity: 0.4;
            cursor: default;
        }
    }
`

const EmailAlreadyKnownMessage = styled.p`
    max-width: 32rem;
    margin: 1.25rem 0 0;
    display: flex;

    svg {
        flex-shrink: 0;
        height: 1.75rem;
        margin: 0.125rem 0.5rem 0 0;
    }
`

export default ReferralCode

export const query = graphql`
    fragment ReferralCodeFragment on Craft_components_referralCode_BlockType {
        greetingNotLoggedInUser
        introductionNotLoggedInUser
        privacyPolicyAcceptText
        loginEmailAddressLabel
        loginButtonText
        emailAlreadyKnownMessage
        greetingLoggedInUser
        introductionLoggedInUser
        copyButtonText
        shareViaEmailText
        shareViaWhatsAppText
        shareViaTwitterText
        shareViaLinkedInText
        socialMediaShareTitle
        socialMediaShareText
    }

    fragment ReferralCodeReusableComponentFragment on Craft_componentType_referralCode_BlockType {
        greetingNotLoggedInUser
        introductionNotLoggedInUser
        privacyPolicyAcceptText
        loginEmailAddressLabel
        loginButtonText
        emailAlreadyKnownMessage
        greetingLoggedInUser
        introductionLoggedInUser
        copyButtonText
        shareViaEmailText
        shareViaWhatsAppText
        shareViaTwitterText
        shareViaLinkedInText
        socialMediaShareTitle
        socialMediaShareText
    }
`
