import React from 'react'
import TableMobileRow from './TableMobileRow'
export type TableMobileLabelData = { label: string; value: string; description?: string[] }
type TableMobileCardProps = {
    index: number
    header: string
    data: TableMobileLabelData[]
}
const MobileTable = ({ header, index, data }: TableMobileCardProps): JSX.Element => {
    return (
        <>
            <header>
                <h3>{header}</h3>
            </header>
            {data.map(row => (
                <TableMobileRow key={index} row={row} />
            ))}
        </>
    )
}
export default MobileTable
