import {
    currentYear,
    getBijtellingWithoutOwnContribution,
    getNetBijtellingResultsNonElectricCar,
    nextYear,
} from '@mistergreen/shared/utilities/bijtelling'
import { ConfigurationModel } from '../../utilities/create-pages/fetch-model-information'
import formatWithCurrencySymbol from '../../utilities/general/format-with-currency-symbol'
import { getBijtellingSavingsText } from '../../utilities/general/bijtelling'
import { graphql } from 'gatsby'
import MaterialInput from '../ui/material-input'
import MaterialRadioButtons from '../ui/material-radio-buttons'
import MaterialSelect from '../ui/material-select'
import React, { useState } from 'react'
import styled from 'styled-components'

interface BijtellingCalculatorContent {
    modelLabel: string
    otherModelLabel: string
    editionLabel: string
    fiscalValueLabel: string
    typeOfCarLabel: string
    electricCarLabel: string
    nonElectricCarLabel: string
    tableCaption: string
    firstTaxBracketHeading: string
    secondTaxBracketHeading: string
    savingsText: string
}

interface BijtellingCalculatorProps {
    configurationModels: ConfigurationModel[]
    data: BijtellingCalculatorContent
}

const BijtellingCalculator = ({ data, configurationModels }: BijtellingCalculatorProps): JSX.Element => {
    const models = configurationModels.map(({ name }) => name)
    const modelSelectables = [
        ...models.map(model => ({ name: model, value: model })),
        { name: data.otherModelLabel, value: 'other' },
    ]

    const [model, setModel] = useState(models[0])
    const [editions, setEditions] = useState(configurationModels[0].editions.map(({ name }) => name))
    const [edition, setEdition] = useState(configurationModels[0].editions[0].name)
    const [fiscalValue, setFiscalValue] = useState(configurationModels[0].editions[0].fiscalValue)
    const [isCarElectric, setIsCarElectric] = useState(true)
    const [bijtellingResults, setBijtellingResults] = useState(getBijtellingWithoutOwnContribution(fiscalValue))

    const handleModelChange = (selectedValue: string) => {
        const selectedModel = configurationModels.find(({ name }) => name === selectedValue)
        setModel(selectedModel?.name || 'other')

        const newEditions = selectedModel?.editions?.map(({ name }) => name) || editions
        setEditions(newEditions)
        setEdition(newEditions[0])

        const newFiscalValue = selectedModel?.editions?.map(({ fiscalValue }) => fiscalValue)[0] || 48980
        setFiscalValue(newFiscalValue)
        setBijtellingResults(getBijtellingWithoutOwnContribution(newFiscalValue))
    }

    const handleEditionChange = (selectedValue: string) => {
        setEdition(selectedValue)

        const selectedModel = configurationModels.find(({ name }) => name === model)
        const selectedEdition = selectedModel?.editions?.find(({ name }) => name === selectedValue)

        const newFiscalValue = selectedEdition?.fiscalValue || fiscalValue
        setFiscalValue(newFiscalValue)
        setBijtellingResults(getBijtellingWithoutOwnContribution(newFiscalValue))
    }

    const handleManualFiscalValueChange = (formFieldValue: string) => {
        const newFiscalValue = parseInt(formFieldValue)

        if (isNaN(newFiscalValue)) {
            return
        }

        setFiscalValue(newFiscalValue)

        isCarElectric
            ? setBijtellingResults(getBijtellingWithoutOwnContribution(newFiscalValue))
            : setBijtellingResults(getNetBijtellingResultsNonElectricCar(newFiscalValue))
    }

    const handleIsCarElectricChange = (formFieldValue: string) => {
        const newIsCarElectricValue = formFieldValue === 'electric'

        setIsCarElectric(newIsCarElectricValue)

        newIsCarElectricValue
            ? setBijtellingResults(getBijtellingWithoutOwnContribution(fiscalValue))
            : setBijtellingResults(getNetBijtellingResultsNonElectricCar(fiscalValue))
    }

    return (
        <Container>
            <MaterialSelect
                currentValue={model}
                label={data.modelLabel}
                onChange={value => handleModelChange(value)}
                selectables={modelSelectables}
            />
            {model !== 'other' ? (
                <MaterialSelect
                    currentValue={edition}
                    label={data.editionLabel}
                    onChange={value => handleEditionChange(value)}
                    selectables={editions.map(edition => ({ name: edition, value: edition }))}
                />
            ) : (
                <>
                    <MaterialInput
                        defaultValue={fiscalValue.toString()}
                        label={data.fiscalValueLabel}
                        onChange={value => handleManualFiscalValueChange(value)}
                        type="number"
                    />
                    <MaterialRadioButtons
                        defaultValue={'electric'}
                        label={data.typeOfCarLabel}
                        onChange={value => handleIsCarElectricChange(value)}
                        selectables={[
                            { value: 'electric', label: data.electricCarLabel },
                            { value: 'non-electric', label: data.nonElectricCarLabel },
                        ]}
                    />
                </>
            )}
            <Table>
                <caption>{data.tableCaption}</caption>
                <thead>
                    <tr>
                        <th className="th__empty" />
                        <th>{currentYear}</th>
                        <th>{nextYear}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>{data.firstTaxBracketHeading}</th>
                        <td>
                            {formatWithCurrencySymbol(bijtellingResults.bracketOneResultCurrentYear, 'nl-NL', {
                                hardcodeCurrency: 'EUR',
                                displayAsFloat: false,
                            })}
                        </td>
                        <td>
                            {formatWithCurrencySymbol(bijtellingResults.bracketOneResultNextYear, 'nl-NL', {
                                hardcodeCurrency: 'EUR',
                                displayAsFloat: false,
                            })}
                        </td>
                    </tr>
                    <tr>
                        <th>{data.secondTaxBracketHeading}</th>
                        <td>
                            {formatWithCurrencySymbol(bijtellingResults.bracketTwoResultCurrentYear, 'nl-NL', {
                                hardcodeCurrency: 'EUR',
                                displayAsFloat: false,
                            })}
                        </td>
                        <td>
                            {formatWithCurrencySymbol(bijtellingResults.bracketTwoResultNextYear, 'nl-NL', {
                                hardcodeCurrency: 'EUR',
                                displayAsFloat: false,
                            })}
                        </td>
                    </tr>
                </tbody>
            </Table>
            {bijtellingResults.bracketOneResultCurrentYear > 10 &&
                bijtellingResults.bracketOneResultNextYear - bijtellingResults.bracketOneResultCurrentYear > 10 && (
                    <BottomText
                        dangerouslySetInnerHTML={{
                            __html: getBijtellingSavingsText(data.savingsText, bijtellingResults),
                        }}
                    />
                )}
        </Container>
    )
}

export default BijtellingCalculator

const Container = styled.div`
    align-self: flex-start;
    max-width: 26rem;
    width: 100%;
    background-color: var(--color-white);
    padding: 1.25rem 1.25rem 1.625rem;
    box-shadow: var(--box-shadow);
    margin: 0 auto;
    > * + * {
        margin: 1.75rem 0 0;
    }
`

const Table = styled.table`
    width: 100%;
    color: var(--color-black);
    border-collapse: collapse;
    caption-side: bottom;
    caption {
        font-size: var(--font-size-small);
        margin: 0 0 0.125rem;
    }
    @media (min-width: 25rem) {
        caption-side: top;
        caption {
            width: 12rem;
            margin-left: auto;
        }
    }
    tr > :not(:first-child) {
        width: 6rem;
    }
    th {
        font-size: var(--font-size-small);
        font-weight: var(--font-weight-bold);
        &:first-child {
            text-align: left;
        }
    }
    td {
        font-family: var(--font-headings);
        font-size: var(--font-size-medium);
        text-align: center;
    }
    th:not(.th__empty),
    td {
        padding: 0.375rem;
        border: 0.0625rem solid var(--color-grey);
    }
`

const BottomText = styled.p`
    color: var(--color-black-light);
    font-size: var(--font-size-small);
    margin: 1.25rem 0 0;
    span {
        color: var(--color-black);
        font-weight: var(--font-weight-bold);
    }
`

export const query = graphql`
    fragment BijtellingCalculatorFragment on Craft_components_bijtellingCalculator_BlockType {
        modelLabel: bijtellingModelLabel
        otherModelLabel
        editionLabel
        fiscalValueLabel: bijtellingFiscalValueLabel
        typeOfCarLabel
        electricCarLabel
        nonElectricCarLabel
        tableCaption
        firstTaxBracketHeading
        secondTaxBracketHeading
        savingsText
    }
    fragment BijtellingCalculatorReusableComponentFragment on Craft_componentType_bijtellingCalculator_BlockType {
        modelLabel: bijtellingModelLabel
        otherModelLabel
        editionLabel
        fiscalValueLabel: bijtellingFiscalValueLabel
        typeOfCarLabel
        electricCarLabel
        nonElectricCarLabel
        tableCaption
        firstTaxBracketHeading
        secondTaxBracketHeading
        savingsText
    }
`
